
  #campaignStatus{
    padding-top: 10px;
    background-color: white;

    .status_date_pick {
       margin-top:13px;
    }

    .status{


      h1{
        font-size: 26px;
        color: #252525;
        font-weight: 600;
      }
      h2{
        font-size: 20px;
        line-height: 18px;
        color: #616161;
        font-weight: 500;
      }
      p{
        font-size: 14px;
        line-height: 18px;
        color: #aaaaaa;
        font-weight: 500;
      }
      span{
        font-size: 18px;
        line-height: 2;
        color: #aaaaaa;
        font-weight: 500;
        strong{
          color: #616161;
        }
      }
      .preview, .edit-page, .share{
        margin: 10px 20px 0 0;
        font-size: 16px;
        color: #88c559;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .pause{
      #xcube-filled-button{
        float: right;
      }
    }
    hr{
      border-top: 1px dashed #bfbfbe;
    }
    #xCubeDatetimePicker{
      padding: 20px;
    }
    #campaignLineChart{
      width: 100%;
    }
    .at-a-glance{
      h2{
        font-size: 26px;
        color: #252525;
        font-weight: 600;
      }
      .card-title{
        margin-bottom: 0.75rem;
      }
      .card:hover{
        filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
        border: 1px solid #ffffff;
        cursor: pointer;
      }
      .card-edit-img{
        position: absolute;
        right: 0;
        transform: translate(50%, -15px);
        display: none;
      }
      .card:hover{
        .card-edit-img{
          display: block;
          cursor: pointer;
        }
      }
    }
    .statistics-row{
      h2{
        font-size: 26px;
    color: #252525;
    font-weight: 600;
      }
      .export-btn{
        #xcube-filled-button{
          float:right;
        }
      }
      .select-row{
        #select {
          select{
            background-color: white !important;
            color: #252525;
          }
          .form-label{
            background-color: white !important;
            color: #8c8c8c;
          }
          .form-group{
            background-color: white !important;
          }
        }
      }
    }
    #xCubeDatePicker{
      padding: 7px 0 0 0;
    }
    .leads-row{
      h2{
        font-size: 30px;
        color: #252525;
        font-weight: 300;
      }
      h4 {
        font-size: 26px;
        color: #252525;
        font-weight: 600;
      }
      .export-btn{
        #xcube-filled-button{
          float:right;
        }
      }
      .table{
        th{
          font-size: 14px;
          color: #616161;
          font-weight: 700;
          img{
            width: 17px;
            cursor: pointer;
          }
        }
        thead{
          tr{
            background-color: #ebebeb;
            th{
              border: none;
            }
          }
        }
        tbody{
          tr{
            border-bottom: 1px dashed #dddddd;
            td{
              border-top: none;
            }
          }
        }
      }
    }

    .sc-fzpjYC {
      color: #616161;
    }

    .sc-AxiKw {
      background-color: #ebebeb;
      border-bottom: none;
    }

    .sc-fzoNJl {
      color: #616161;
    }

    .doBktq:not(:last-of-type) {
      border-bottom-style: dashed;
      border-bottom-width: 1px;
      border-bottom-color: rgba(0,0,0,.12);
  }

    @media (max-width: 320px) {
      .status {

        .btn-group {
          display: inline-block;
        }

        .share {
          display: inline-block;
          margin: 10px 0px 0 0;
        }

        div.share {
          display: inline-block;
          margin-left: 0px;
        }

        .leadsRowTable {
          overflow-x: auto;
        }


      }

      .pause {
        padding-top: 10px;
      }
      .leads-row {
        overflow-x: auto;
        .export-btn {
          margin-bottom: 14px;
          padding-left: 60px;
          #xcube-filled-button {
            float: left;
          }
        }
      }

      #campaignStatusEngagement {
        .card {
          .card-title {
            font-size: 25px;
            color: #8c8c8c

          }
        }
      }

      #dashboardConversions {
        .card {
          .card-title {
            font-size: 25px;
            color: #8c8c8c
          }
        }
      }

    }

    @media  (min-width: 321px ) and (max-width: 767px) {
      .status {
        .btn-group {
          display: inline-block;
        }

        .share {
          display: inline-block;
          margin: 10px 0px 0 0;
        }

        div.share {
          display: inline-block;
          margin-left: 0px;
        }

        .leadsRowTable {
          overflow-x: auto;
        }


      }
      .leads-row {
        overflow-x: auto;
        .export-btn {
          margin-bottom: 14px;
          padding-left: 80px;
          #xcube-filled-button {
            float: left;
          }
        }
      }

      #campaignStatusEngagement {
        .card {
         .card-title {
           font-size: 25px;
           color: #8c8c8c

         }
        }
      }

      #dashboardConversions {
        .card {
         .card-title {
           font-size: 25px;
           color: #8c8c8c
         }
        }
      }

    }

  }



