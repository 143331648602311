#xcube-checkbox {

  .custom-checkbox .custom-control-label::before {
    border-radius: 2px !important;
    //background: linear-gradient(0deg, #393939 0%, #252525 100%) !important;
  }

  .custom-control-label::before {
    height: 1.5rem !important;
    width: 1.5rem !important;
    border-color: #aaaaaa !important;
    background: none !important;
  }

  .custom-control-label::after{
    height: 1.5rem !important;
    width: 1.5rem !important;
    border-color: #5d5d5d !important;
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
    background-image: url("./assets/checked.svg");
    background-size: cover;
  }

  .text-label{
    color: #616161;
    margin-left: 1.75rem;
    vertical-align: sub;
    font-size: 14px;
    font-weight: 500;
  }

  .checkbox-label{
    font-size: 12px;
    margin-left: 1.75rem;
    line-height: 18px;
    color: #aaaaaa;
    font-weight: 500;
  }
}