#campaignEmails{
  #xcube-input{
    width: 100%;
    .background-image{
      background-image: none !important;
    }
    .xcube-input-label{
      background-color: white !important;
      color: #8c8c8c;
      font-size: 14px;
    }
    input{
      background-color: white !important;
      border: 1px solid #ced4dc;
    }
  }
  .section-head{
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
  }
  background-color: white;
  .edit-page-btn{
    #xcube-filled-button{
      float: right;
    }
  }
  .btn-group-basics{
    width: 100%;
  }
  hr{
    border-top: 1px dashed #bfbfbe;
  }
  #xcubeTextEditor{
    padding: 20px 20px 20px 0;
    .ck-editor__editable_inline {
      min-height: 300px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #ced4dc;
    }
    .ck-toolbar_grouping{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid #ced4dc;
      border-bottom: none;
    }
  }
  #xcube-checkbox {
    padding: 17px 17px 17px 0;
    .custom-checkbox {
      .custom-control-label::before{
        background: none !important;
        border-color: #aaaaaa;
      }
    }
  }
  .test-email{
    line-height: 2;
    font-size: 16px;
    color: #88c559;
    font-weight: 500;
    cursor: pointer;
  }
  .tinymce-editor{
    padding: 0 20px 20px 0;
    .tox{
      border: 1px solid #ced4dc;
      border-radius: 4px;
    }
  }
  .introduction{
    .tox{
      height: 200px !important;
    }
  }
  .xcube-label{
    color: #8c8c8c;
    position: absolute;
    padding: 0 10px;
    font-size: 14px;
  }
}
