#intro-inspiration{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  transform: translate(0, -75px);
  padding-bottom: 10rem;
  padding-top: 5rem;
  h3{
    font-size: 40.284px;
    color: rgb(252, 252, 255);
    text-transform: uppercase;
    line-height: 1;
    text-align: right;
    font-weight: 800;
  }
  p{
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 1.625;
    text-align: right;
  }
  .card{
    border: none;
    filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.25));
    .card-footer{
      padding: 2.5px;
      text-align: center;
      font-size: 14px;
      color: white;
      background-color: #2c2c2c;
    }
  }
  @media only screen and (max-width: 600px) {
    transform: translate(0, -125px);
    padding-top: 7rem;
    padding-bottom: 0;
  }
}