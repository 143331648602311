
.devicesUsed {
  color: #8c8c8c;

  h4 {
    font-weight: 400;
  }

  //.devicesColorsSpans {
  //  display: inline-block;
  //  width: 10px;
  //  height: 10px;
  //  background-color: green;
  //  border-radius: 50%;
  //  margin-right: 10px;
  //}
  .deviceDetails {
    width: 800px;
    height: 300px;
  }


  @media(max-width: 320px) {

    .deviceDetails {
      width: 310px;
      height: 200px;
    }

  }

  @media(min-width: 321px) and (max-width: 767px) {

    .deviceDetails {
      width: 320px;
      height: 240px;
    }

  }

}
