#createCampaignWizardStart{
  .card{
    background-color: #f5f5f5;
    .card-body{
      img{
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 100px;
      }
      h3{
        font-size: 30px;
        color: #252525;
        font-weight: 300;
        text-align: center;
        margin-top: 30px;
      }
      p{
        font-size: 20px;
        color: #252525;
        font-weight: 300;
        text-align: center;
        margin-bottom: 0;
      }
      small{
        font-size: 14px;
        color: #aaaaaa;
        font-weight: 500;
        text-align: center;
        display: block;
      }
      #xcube-filled-button {
        button{
          float: right;
          margin: 30px;
        }
      }
      #xcube-input {
        padding: 20px 0;
        .xcube-input-label, .xcube-input{
          background-color: #f5f5f5 !important;
        }
        .xcube-input:focus .xcube-input-label{
          color: red !important;
        }
        .typing:focus .xcube-input-label{
          color: red !important;
        }
      }
    }
  }
  .xcubeDateTimePicker{
    input{
      background-color: transparent;
    }
    label{
      background-color: #f5f5f5 !important;
    }
    .form-control{
      background-color: #f5f5f5 !important;
    }
  }
  #select{
    padding: 20px 0 40px 0;
    .xcube-input-label{
      background-color: #f5f5f5 !important;
    }
    .react-inputs-validation__select__container___3jgUR{
      padding: 1.5% 2% 1% 2%;
    }
  }

  @media (max-width: 767px) {
    #xCubeDatetimePicker {
      margin-bottom: 15px;
    }
  }
}
