.detailsConversion {

  @media(max-width: 320px) {


    width: 310px;
    height: 240px;


  }

  @media(min-width: 321px) and (max-width: 767px) {


    width: 320px;
    height: 240px;


  }

  @media(min-width: 768px) and (max-width: 991px) {


    width: 700px;
    height: 300px;


  }

  @media(min-width: 992px) {


    width: 800px;
    height: 300px;


  }






}
