#dashboardAccountTeam{
  background-color: white;
  .section-head {
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
  }
  .media{
    img{
      border-radius: 50%;
    }
  }
  .edit-user{
    display: inline-block;
    .edit-user-btn{
      color: #88c559 !important;
      cursor: pointer;
    }
  }
  .new-campaign-btn{
    #xcube-filled-button{
      float: right;
      button{
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  .media-add{
    margin: 2em auto;
    cursor: pointer;
    a{
      text-decoration: none;
    }
    img{
      margin-right: 15px;
    }
    .media-body{
      color: #8c8c8c;
      font-size: 16px;
      font-weight: lighter;
      h5{
        color: #252525;
        font-size: 20px;
        font-weight: lighter;
      }
    }
  }
  .table{
    th{
      font-size: 14px;
      color: #616161;
      font-weight: 700;
    }
    thead{
      tr{
        background-color: #ebebeb;
        th{
          border: none;
        }
      }
    }
    tbody{
      font-size: 18px;
      line-height: 17px;
      color: #616161;
      font-weight: 500;
      tr{
        border-bottom: 1px dashed #dddddd;
        td{
          border-top: none;
          .user_image{
            width: 44px;
            height: 44px;
          }
          p{
            font-size: 14px;
            color: #aaaaaa;
            line-height: 2;
          }
          .options{
            display: none;
            margin-top: 15px;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 0;
            }
            a:last-child{
              color: #f78b60;
            }
          }
          .options2{
            margin-top: 5px;
            color: #aaaaaa;
            font-size: 12px;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 5px;
            }
          }
        }
      }
      tr:hover{
        filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
        background-color: #f5f5f5;
        border: 1px solid #ffffff;
        .options, .options2{
          display: block;
          span{
            color: #f78b60;
          }
          img{
            margin-right: 5px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .tabLead {
      overflow-x: auto;
      .table {
        tr:hover{
          filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
          background-color: #f5f5f5;
          border: 1px solid #ffffff;
          .options, .options2{
            display: grid;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            
            }
          }
        }
      }
      
      .table {
        tr:hover{
          filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
          background-color: #f5f5f5;
          border: 1px solid #ffffff;
          .options a{
           font-size: 13px;
          }
        }
      }

    }
  }
}