#xCubeDatetimePicker{
  font-family: "Raleway", sans-serif;
  .xcubeDateTimePicker{
    border: 1px solid #ced4dc;
    border-radius: 4px;
    //padding: 15px 10px 5px 20px;
    padding: 6px 10px 5px 20px;
    label{
      position: absolute;
      transform: translate(-10px, -17px);
      background-color: white;
      padding: 0 10px;
      margin: 0;
      font-size: 12px;
      color: #8c8c8c;
      font-weight: 400;
    }
    .dateLabel, .timeLabel{
      color: #252525;
    }
    .datePicker{
      .react-date-picker__wrapper{
        border: none;
        .react-date-picker__inputGroup__input{
          color: #88c559;
        }
      }
    }
    .timePicker{
      .react-time-picker__wrapper{
        border: none;
        .react-time-picker__inputGroup__input, .react-time-picker__inputGroup__leadingZero{
          color: #88c559;
        }
      }
    }
  }
}
