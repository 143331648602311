#dashboard{
  background-image: url("./assets/BG.svg");
  background-size: cover;
  font-family: "Raleway", sans-serif;
  min-height: 100vh;
  .container-fluid{
    margin: 0;
    padding: 0;
  }
}





