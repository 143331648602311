#createCampaignWizardPrizes{
  .card{
    background-color: #f5f5f5;
    .card-header{
      background-color: #f5f5f5;
      border: none;
      img{
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      h3{
        font-size: 25px;
        color: #252525;
        font-weight: 300;
      }
      p{
        font-size: 18px;
        color: #252525;
        font-weight: 300;
        margin: 0;
      }
      small{
        font-size: 14px;
        color: #aaaaaa;
        font-weight: 500;
        display: block;
        width: 64%;
      }
      #xcube-filled-button {
        button{
          float: right;
          margin: 30px;
        }
      }
      #select{
        select{
          background-color: #f5f5f5 !important;
          color: #252525;
          font-size: 16px;
        }
        .form-group{
          background-color: #f5f5f5 !important;
          .form-label{
            background-color: #f5f5f5 !important;
            font-size: 12px;
          }
        }
      }
    }
    .winner-email{
      #xcube-input{
        padding-right: 0;
      }
      .tox {
        .tox-edit-area__iframe {
          background-color: #f5f5f5;
        }
        .tox-toolbar__primary{
          background-color: #f5f5f5;
        }
      }
    }
    #xCubeInputFile {
      margin: 20px 20px 20px 0;
      .xcube-input-label{
        background-color: #f5f5f5;
      } 
      .xcube-file-upload {
        font-size: 1rem;
      };
    }
    .accordion{
      .card-header{
        background-color: #ebebeb;
        button{
          font-size: 18px;
          color: #252525;
          font-weight: 300;
        }
      }
      .card-body{
        //background-color: #ebebeb;
        padding:0;
      }
      thead{
        th{
          color: #252525b8;
          background-color: rgba(0, 0, 0, 0.11);
          font-weight: 500;
          font-size: 15px;
        }
      }
      tbody{
        td{
          img{
            max-width: 80px;
            max-height: 80px;
          }
          .deleteBtn{
            margin-right: 20px;
          }
        }
      }
    }
    .xcube-label{
      color: #8c8c8c;
      position: absolute;
      padding: 0 10px;
      font-size: 12px;
    }
    .introduction{
      .tox{
        height: 200px !important;
      }
    }
    .card-body{
      .xcube-card-body{
        background-color: #ebebeb;
        .card{
          border-top: 1px dashed #bdbdbd;
          border-bottom: none;
          cursor: pointer;
          .card-footer{
            padding: 2.5px;
            text-align: center;
            font-size: 14px;
            color: white;
            background-color: #2c2c2c;
          }
        }
        .active{
          padding: 2px;
          background-image: linear-gradient(45deg, #57ad63 0%, #cbc046 100%);
          .card-footer{
            background-color: transparent;
          }
        }
      }
    }
    .xcube-card-footer{
      background-color: white;
      border: none;
      #xcube-text-button{
        margin-right: 3rem;
      }
      .btn-group{
        #xcube-filled-button{
          margin-top: 5px;
        }
      }
    }

    @media (max-width:767px) {
     
       
        .xcube-card-header {
          text-align: center;
          .divImg {
            img {
              width: 100px;
            }
          }
          small {
            text-align: center;
            width: 100%;
          }
        }
  
        #xCubeInputFile {
          margin: 20px 20px 20px 0;
          .xcube-file-upload{
            font-size: 13px;
            img {
              right: 15%;
            }
          }
        }
      
    }

    @media (min-width: 768px) and (max-width: 991px) {

      .xcube-card-header {
        text-align: center;
        small {
          text-align: center;
          width: 100%;
        }
      }
      
      #xCubeInputFile {
        margin: 20px 0px 20px 0;
        .xcube-file-upload{
          font-size: 12px;
          img {
            right: 10%;
          }
        }
      }
    }


  }

}



 



