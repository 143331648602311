#emailDialog{
  font-family: "Raleway", sans-serif;
  width: 50%;
  max-width: 50%;
  .modal-body{
    background-color: #f5f5f5;
    padding: 30px 50px;
    .media{
      border: 1px dashed #aaaaaa;
      padding: 10px;
      border-radius: 4px;
      h5{
        color: #252525;
        font-size: 18px;
        font-weight: lighter;
        line-height: 2.5;
        margin: 0;
      }
    }
    .card{
      background-color: transparent;
      border: 1px dashed #aaaaaa;
      text-align: center;
      .card-img{
        width: 70px;
      }
      .card-text{
        color: #8c8c8c;
        font-size: 12px;
      }
      .card-title{
        color: #616161;
        font-size: 16px;
      }
      .card-img-col{
        margin: 30px auto 10px auto;
      }
    }
  }
  .modal-header{
    span{
      font-weight: 300;
      font-size: 20px;
      color: #252525;
      font-family: "Raleway", sans-serif;
    }
    small{
      color: #aaaaaa;
      font-size: 14px;
      line-height: 1.2;
    }
    button{
      span{
        color: #99cc66 !important;
        font-family: auto;
        font-weight: 500;
        font-size: 25px;
      }
    }
  }
  .modal-footer{
    #xcube-input{
      margin-right: auto;
      padding: 0;
    }
  }
}