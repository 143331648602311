#campaignPrizes{
  background-color: white;
  .import-button{
    display: none;
  }
  .section-head{
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
  }
  .question-btn{
    #xcube-filled-button{
      float: right;
      button{
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  .table{
    th{
      font-size: 14px;
      color: #616161;
      font-weight: 700;
    }
    thead{
      tr{
        background-color: #ebebeb;
        th{
          border: none;
        }
      }
    }
    tbody{
      font-size: 18px;
      line-height: 17px;
      color: #616161;
      font-weight: 500;
      tr{
        border-bottom: 1px dashed #dddddd;
        td{
          border-top: none;
          img{
            max-width: 100px;
            max-height: 60px;
          }
          p{
            font-size: 14px;
            color: #aaaaaa;
            line-height: 2;
          }
          .options{
            display: none;
            margin-top: 15px;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 0;
              cursor: pointer;
            }
            a:last-child{
              color: #f78b60;
            }
          }
        }
      }
      tr:hover{
        filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
        background-color: #f5f5f5;
        border: 1px solid #ffffff;
        .options{
          display: block;
        }
      }
    }
  }
  .import-export{
    text-align: center;
    margin: 3rem 0 3rem 0;
    a{
      color: #88c559;
      font-size: 16px;
      cursor: pointer;
      margin: .5rem;
    }
    span{
      margin-right: .5rem;
      color: #88c559;
    }
  }

  @media (max-width: 767px) {
    .prizeOverflowX {
      overflow-x: auto;
    }

    .question-btn {
      #xcube-filled-button {
        margin-bottom: 15px;
      }
    }

    .table{
      th{
        font-size: 14px;
        color: #616161;
        font-weight: 700;
      }
      thead{
        tr{
          background-color: #ebebeb;
          th{
            border: none;
          }
        }
      }
      tbody{
        font-size: 18px;
        line-height: 17px;
        color: #616161;
        font-weight: 500;
        tr{
          border-bottom: 1px dashed #dddddd;
          td{
            border-top: none;
            img{
              max-width: 100px;
              max-height: 80px;
            }
            p{
              font-size: 14px;
              color: #aaaaaa;
              line-height: 2;
            }
            .options{
              display: none;
              margin-top: 15px;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 0;
              }
              a:last-child{
                color: #f78b60;
              }
            }
            .options2{
              display: none;
              margin-top: 15px;
              bottom: 10px;
              position: absolute;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 5px;
              }
            }
          }
        }
        tr:hover{
          filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
          background-color: #f5f5f5;
          border: 1px solid #ffffff;
          .options, .options2{
            display: grid;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            }
          }
        }
      }
    }


  }




}
