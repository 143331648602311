#public-navbar{
  .navbar{
    position: absolute;
    width: 100%;
    background-image: linear-gradient(0deg, rgba(18,18,18,0) 0%, rgba(18,18,18,0.6) 100%);
    .navbar-brand{
      img{
        width: 80%;
      }
    }
    .nav-link{
      font-size: 16px;
      color: #f5f5f5;
      font-weight: 500;
      text-align: right;
      line-height: 3;
      padding-right: 1.5rem;
    }
    .avatar-link{
      color: white;
      line-height: 4;
      span{
        transform: translate(-170%, 36%);
        display: inline-table;
        font-size: 12px;
        color: #dddddd;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .navbar-expand .navbar-toggler{
      display: block;
    }
    .collapse:not(.show){
      display: none !important;
    }
    .navbar-expand .navbar-nav{
      flex-direction: column;
    }
    .navbar-toggler{
      background-color: white;
    }
    .navbar-collapse{
      position: absolute;
      background: black;
      top: 0;
      margin-left: 37%;
      z-index: 1;
    }
    .nav-link{
      padding-right: .5rem !important;
      text-align: center !important;
    }
  }
}