#xcube-spinner{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000cf;
  z-index: 9999999999999;
  .sweet-loading{
    margin: 20rem auto;
    width: fit-content;
  }
}
