#dashboardAccountGeneral{
  background-color: white;
  .section-head{
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
    span{
      color: #616161;
      font-size: 18px;
      font-weight: 500;
      line-height: 2;
    }
    a{
      color: #88c559;
    }
  }
  #xcube-checkbox {
    padding: 20px 20px 20px 0;
    .custom-checkbox {
      .custom-control-label::before{
        background: none !important;
        border-color: #aaaaaa;
      }
    }
  }
  #xcube-input{
    width: 100%;
    .xcube-input-label{
      background-color: white !important;
      color: #8c8c8c;
      font-size: 12px;
    }
    input{
      background-color: white !important;
      //border: 1px solid #8c8c8c;
    }
  }
  hr{
    border-top: 1px dashed #bfbfbe;
  }
  #xcube-filled-button{
    padding: 15px;
  }
  #select{
    padding: 20px 20px 20px 0;
    width: 100%;
    .form-group{
      background-color: white !important;
      .form-label{
        background-color: white;
        color: #8c8c8c;
        font-weight: 400;
      }
      select{
        background-color: white !important;
        color: #252525;
        font-size: 1rem;
      }
    }
  }
}