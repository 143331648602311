#hero-why-brame-page{
  background-image: url("./assets/hero-bg.png");
  background-size: cover;
  .hero-text{
    text-align: center;
    margin: 20em 0;
    h4{
      color: #dddddd;
      font-size: 24px;
    }
    h1{
      color: #ffffff;
      font-size: 100px;
      font-weight: 900;
      line-height: 0.9;
      padding: 0 0 30px 0;
      text-transform: uppercase;
      span{
        background: linear-gradient(to right, #58ad64, #cbc047 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .hero-text {
      margin: 10em 0;
      h1{
        font-size: 50px;
      }
    }
  }
}