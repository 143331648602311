#xcubeAppointmentPicker{
  font-family: "Raleway", sans-serif;
  padding: 6px 20px 5px 0px;
  .xcubeAppointmentPicker{
    border: 1px solid #ced4dc;
    border-radius: 4px;
    padding: 6px 10px 5px 10px;

    background-image: url(./assets/calendar.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-position-x: 97%;

    label{
      position: absolute;
      transform: translate(0px, -17px);
      background-color: #f3f3f3;
      padding: 0px 4px;
      margin: 0;
      font-size: 12px;
      color: #8c8c8c;
      font-weight: 400;
      z-index: 1;
    }
    .dateLabel, .timeLabel{
      color: #252525 !important;
    }
    .date, .time{
      color: #88c559 !important;
    }
    #button{
      cursor: pointer;
    }

    .rdtStatic{
      position: absolute;
      right: 252px;
      transform: translateY(-16px);
    }
    
    .rdtOpen .rdtPicker {
      display: block;
    }

    .rdtOpen .rdtPicker > .rdt::after{
      visibility:visible;
    }

    .rdtClosed .rdtPicker {
      display: none;
    }
    .rdtPicker {
      display: none;
      position: absolute;
      width: 250px;
      padding: 4px;
      margin-top: 1px;
      z-index: 99999 !important;
      background: #fff;
      box-shadow: 0 1px 3px rgba(0,0,0,.1);
      border: 1px solid #f9f9f9;
      bottom: 50px;
      color: #8c8c8c !important;
      .rdtBtn{
        color: #88c559 !important;
        text-align: center;
        line-height: 50px;
      }
      .rdtTimeToggle{
        color: #88c559 !important;
      }
      .rdtPrev span, .rdtNext span {
        color: #88c559 !important;
        text-align: center;
        font-size: 28px;
        line-height: 100%;
      }
      /*.rdtPrev:after {
        content: '<';
        color: #88c559 !important;
      }
      .rdtNext:after {
        content: '>';
        color: #88c559 !important;
      }*/
      td.rdtActive{
        background-color: transparent !important;
        border:1px solid #88c559 !important;
        border-radius: 2px;
        color: #8c8c8c !important;
      }
      td.rdtToday:before {
        border-bottom: 7px solid #88c559;
      }
      td.rdtOld, td.rdtNew, td.rdtDisabled{
        color: #cfcfcf !important;
      }
      tbody>tr>:nth-child(6):not(.rdtOld):not(.rdtNew), tbody>tr>:nth-child(7):not(.rdtOld):not(.rdtNew){
        color:rgb(255, 194, 194) !important;
      }
      .rdtCounterSeparator {
        line-height: 110px;
      }
    }

    .rdt::after{
      content: "";
      width: 0; 
      height: 0; 
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid white;
      transform: translate(216px, -32px);
      display: inline-block;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}



