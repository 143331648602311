#createCampaignWizardComplete{
  .card{
    background-color: #f5f5f5;
    .card-body{
      img{
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 100px;
      }
      h3{
        font-size: 30px;
        color: #252525;
        font-weight: 300;
        text-align: center;
        margin-top: 30px;
      }
      p{
        font-size: 20px;
        color: #252525;
        font-weight: 300;
        text-align: center;
      }
      small{
        font-size: 14px;
        color: #aaaaaa;
        font-weight: 500;
        text-align: center;
        display: block;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
      #xcube-filled-button {
        button{
          float: right;
          margin: 30px;
        }
      }

      #xcube-text-button{
        button{
          float: right;
          margin: 25px 30px 30px 30px;
        }
      }

      .btn-group{
        transform: translate(100px, 10px);
      }

      input[type=radio] {
        display:none;
        margin:10px;
      }

      input[type=radio] + label {
        display:inline-block;
        margin:-2px;
        padding: 4px 30px;
        background-color: transparent;
        border: none;
      }

      input[type=radio]:checked + label {
        background-image: none;
        background-color: #ebebeb;
        color: #99cc66;
      }
      .selector{
        background-color: rgba(255,255,255,0);
        border: 1px solid rgba(140,140,140,0.16);
        width: fit-content;
        font-size: 14px;
        color: #8c8c8c;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        margin-top: 6%;
        label{
          img{
            padding: 10px 0;
          }
        }
      }
    }


    @media (max-width:320px) {

      .card-body {
        .btn-group{
          transform: translateX(0%);
          max-width: 100%;
          width: 310px;
          // display: none;
          #xcube-filled-button {
            width: 160px;
            #filledButton {
              width: 120px;
            }
          }
          #xcube-text-button {
            width: 130px;
            .normal {
              width: 120px;
            }
          }
        }
      }
      
    }


    @media (min-width:321px) and  (max-width:767px) {

      .card-body {
        .btn-group{
          transform: translateX(5%);
          max-width: 100%;
          width: 310px;
          
          #xcube-filled-button {
            width: 160px;
            #filledButton {
              width: 120px;
            }
          }
          #xcube-text-button {
            width: 130px;
            .normal {
              width: 120px;
            }
          }
        }
      }

     
    }


    
    @media (min-width: 768px) and (max-width: 991px) {

      .card-body {
        .btn-group{
          transform: translateX(10%);
          max-width: 100%;
          width: 310px;
          // display: none;
          #xcube-filled-button {
            width: 160px;
            #filledButton {
              width: 120px;
            }
          }
          #xcube-text-button {
            width: 130px;
            .normal {
              width: 120px;
            }
          }
        }
      }
      
    }

    @media (min-width: 992px) and (max-width: 1199px) {

      .card-body {
        .btn-group{
          transform: translateX(40%);
          max-width: 100%;
          width: 310px;
          // display: none;
          #xcube-filled-button {
            width: 160px;
            #filledButton {
              width: 120px;
            }
          }
          #xcube-text-button {
            width: 130px;
            .normal {
              width: 120px;
            }
          }
        }
      }
      
    }
  }
}