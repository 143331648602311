#createCampaignWizardGdpr{
  .card{
    background-color: #f5f5f5;
    .card-header{
      background-color: #f5f5f5;
      border: none;
      img{
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      h3{
        font-size: 25px;
        color: #252525;
        font-weight: 300;
      }
      p{
        font-size: 18px;
        color: #252525;
        font-weight: 300;
        margin: 0;
      }
      small{
        font-size: 14px;
        color: #aaaaaa;
        font-weight: 500;
        display: block;
        //width: 64%;
      }
      #xcube-filled-button {
        button{
          float: right;
          margin: 30px;
        }
      }
    }
    .card-body{
      p{
        font-family: Raleway;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #616161;
      }
      span{

        font-family: Raleway;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        padding-left: 15px;
        color: #616161;
      }
      .xcube-card-body{
        background-color: #ebebeb;
        padding: 30px;
        .xcube-input{
          background-color: #ebebeb;
        }
        .xcube-input-label{
          background-color: #ebebeb !important;
        }
        .xcube-textarea{
          background-color: #ebebeb;
        }
        .xcube-textarea-label{
          background-color: #ebebeb;
        }
        .react-inputs-validation__select__options-container___d54qE{
          z-index: 2;
        }
      }
      #select{
        select{
          background-color: #ebebeb !important;
          color: #252525;
          font-size: 16px;
        }
        .form-group{
          background-color: #ebebeb !important;
          .form-label{
            background-color: #ebebeb !important;
            font-size: 12px;
          }
        }
      }
    }
    .xcube-card-footer{
      background-color: white;
      #xcube-text-button{
        margin-right: 3rem;
      }
      .btn-group{
        #xcube-filled-button{
          margin-top: 5px;
        }
      }
    }

    @media (max-width: 767px) {

      

      .card-header {
        img {
          width: 100px;
        }
        
      }
    }
  }
}