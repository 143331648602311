#xcube-text-button{
  .negative{
    font-size: 15px;
    color: #f78b60;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  button:hover{
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
    text-decoration: none;
  }
  .normal{
    color: #99cc66;
    font-size: 15px;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    text-align: center;
  }
}