#createCampaignWizardTemplate{
  .card{
    background-color: #f5f5f5;
    .card-header{
      background-color: #f5f5f5;
      border: none;
      img{
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
      h3{
        font-size: 25px;
        color: #252525;
        font-weight: 300;
      }
      p{
        font-size: 18px;
        color: #252525;
        font-weight: 300;
        margin: 0;
      }
      small{
        font-size: 14px;
        color: #aaaaaa;
        font-weight: 500;
        display: block;
        width: 64%;
      }
      #xcube-filled-button {
        button{
          float: right;
          margin: 30px;
        }
      }
      #select{
        select{
          background-color: #f5f5f5 !important;
          color: #252525;
          font-size: 16px;
        }
        .form-group{
          background-color: #f5f5f5 !important;
          .form-label{
            background-color: #f5f5f5 !important;
            font-size: 12px;
          }
        }
      }
    }
    .card-body{
      .xcube-card-body{
        background-color: #ebebeb;
        padding: 1.25rem;
        overflow: auto;
        height: 400px;
        .card{
          border: none;
          filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.25));
          cursor: pointer;
          .card-footer{
            padding: 2.5px;
            text-align: center;
            font-size: 14px;
            color: white;
            background-color: #2c2c2c;
          }
        }
        .active{
          padding: 2px;
          background-image: linear-gradient(45deg, #57ad63 0%, #cbc046 100%);
          .card-footer{
            background-color: transparent;
          }
        }
      }
    }
    .xcube-card-footer{
      box-shadow: 0px -25px 8px rgba(253, 253, 253, 1);
      background-color: white;
      border: none;
      #xcube-text-button{
          margin-right: 3rem;
      }
      .btn-group{
       
        #xcube-filled-button{
          margin-top: 5px;
        }
      }
    }



    @media (max-width: 767px) {

      text-align: center;
      .card-header {
        small {
          width: 100%;
        }
        img {
          width: 100px;
        }
        .imgDiv {
          margin-bottom: 15px;
        }
      }

      .btn-group {
        margin-top: 25px;
      }
    }
  }
}
