#login{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  .container-fluid{
    #xcube-input {
      .xcube-input{
        background-color: #f3f3f3 !important;
      }
      .xcube-input-label{
        background-color: #f3f3f3 !important;
      }
    }
    text-align: center;
    color: white;
    padding-top: 7rem;
    padding-left: 8.5rem;
    padding-right: 7rem;
    .hero-text{
      //margin-bottom: 8rem;
      margin-bottom: 4rem;
      text-align: left;
      h1{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 40.284px;
        line-height: 1.2;
      }
      p{
        font-weight: 500;
        margin-top: 1.5rem;
        font-size: 16px;
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
    }
    .form-row{
      margin-top: 8rem;
      p{
        font-size: 20px;
        color: rgb(37, 37, 37);
        line-height: 1.8;
        text-align: left;
        font-weight: 300;
        margin-bottom: 5px;
      }
      span{
        font-size: 14px;
        color: #8c8c8c;
        line-height: 1.286;
        text-align: left;
        a{
          color: rgb(136, 197, 89);
        }
      }
      #xcube-filled-button{
        margin-top: 1.5rem;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .container-fluid{
      padding-left: 15px;
      padding-right: 15px;
    }
    .d-mob-none{
      display: none;
    }
    .w-50{
      width: 80% !important;
    }
  }
}