.nav {
  //margin-bottom: 15px;
  text-align: center;
}

.dot-div{
  display: inline-flex;
  line-height: 3;
}

.dot {
  color: #f5f5f5;
  cursor: pointer;
  font-size: 70px;
  line-height: 1;
  opacity: .9;
  text-shadow: none;
  transition: opacity 1s ease,
  text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.active {
  color: var(--blue);
  opacity: 1;
  text-shadow: 0 0 8px;
}

.display-none{
  display: none;
}

.display{
  color: #f5f5f5;
}

.rc-steps-item-tail{
  top: 15px;
}

.rc-steps-label-vertical .rc-steps-item-tail{
  padding: 0 !important;
}

.rc-steps-item-finish .rc-steps-item-tail:after{
  background-color: white !important;
}

.rc-steps-item-tail:after{
  height: 2px !important;
}

.normalIcon .rc-steps-item-tail:after{
  border-bottom: 2px dotted lightgrey !important;
  background: transparent !important;
}

.activeIcon .rc-steps-item-tail:after{
  border-bottom: 2px dotted lightgrey !important;
  background: transparent !important;
}

.rc-steps-item-title{
  color: white !important;
  font-weight: 500 !important;
}

.rc-steps{
  width: 55% !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  img{
    width: 80%;
  }
}

.normalIcon{
  img{
    width: 60%;
  }
}

.rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon{
  top: 0 !important;
}
