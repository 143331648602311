#campaignGame{
  background-color: white;
  hr{
    border-top: 1px dashed #bfbfbe;
  }
  #xcube-input{
    width: 100%;
    .background-image{
      background-image: none !important;
    }
    .xcube-input-label{
      background-color: white !important;
      color: #8c8c8c;
      font-size: 14px;
    }
    input{
      background-color: white !important;
      border: 1px solid #ced4dc;
    }
  }
  #select{
    padding: 20px 20px 20px 0;
    width: 100%;
    .form-group{
      background-color: white !important;
      .form-label{
        background-color: white;
        color: #8c8c8c;
        font-weight: 400;
      }
      select{
        background-color: white !important;
        color: #252525;
        font-size: 1rem;
      }
    }
  }
  .section-head{
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
  }
  #xcube-checkbox {
    padding: 20px 20px 20px 0;
    .custom-checkbox {
      .custom-control-label::before{
        background: none !important;
        border-color: #aaaaaa;
      }
    }
  }
  .question-btn{
    #xcube-filled-button{
      float: right;
      button{
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  .table{
    th{
      font-size: 14px;
      color: #616161;
      font-weight: 700;
    }
    thead{
      tr{
        background-color: #ebebeb;
        th{
          border: none;
        }
      }
    }
    tbody{
      font-size: 18px;
      line-height: 17px;
      color: #616161;
      font-weight: 500;
      tr{
        border-bottom: 1px dashed #dddddd;
        td{
          border-top: none;
          .options{
            display: none;
            margin-top: 15px;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 0;
            }
            a:last-child{
              color: #f78b60;
            }
          }
        }
      }
      tr:hover{
        filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
        background-color: #f5f5f5;
        border: 1px solid #ffffff;
        .options{
          display: block;
        }
      }
    }

  }
}
