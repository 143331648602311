.sliding-puzzle-game{
  .secondaryColorPicker, .primaryColorPicker{
    display: inline-flex;
    p{
      font-size: 14px;
      margin-right: 0.5rem;
      margin-bottom: 0;
      color: #8c8c8c;
    }
  }
  .color-picker{
    color: #8c8c8c;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
  span{
    float: left;
    margin-right: 20px;
  }
  .thumbnail-image{

  }
  #xcube-file-upload {
    width: 100%;
    border: 1px dashed lightgrey;
    padding: 10px 20px 0 20px;
    .container-fluid{
      background-color: transparent;
      border-color: transparent;
      h3{
        font-size: 20px;
        color: #252525;
        font-weight: 300;
      }
      p{
        line-height: 18px;
        color: #aaaaaa;
      }
    }
  }
}
