#action-home-page{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  .container{
    text-align: center;
    color: white;
    .hero-text{
      margin-top: 7rem;
      margin-bottom: 8rem;
      h1{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 40.284px;
        line-height: 1.2;
        text-align: center;
      }
      p{
        font-weight: 500;
        margin-top: 1.5rem;
        font-size: 16px;
        line-height: 1.625;
        margin-bottom: 1.5rem;
        text-align: center;
      }
    }
  }
}