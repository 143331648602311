.modal-content{
  height: fit-content;
}
#closeAccount{
  span{
    color: #616161;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
}
#newPaymentModal{

  .modal-body{
    background-color: #f5f5f5;
    .xcube-input-label{
      background-color: #f5f5f5 !important;
    }
    #select {
      .form-group {
        .form-label{
          background-color: #f5f5f5 !important;
        }
        select{
          background-color: #f5f5f5 !important;
        }
      }
    }
    #xCubeDatePicker{
      label{
        background-color: #f5f5f5 !important;
      }
      .form-control{
        background-color: #f5f5f5 !important;
      }
    }
  }
  .modal-footer{
    background-color: transparent;
  }
  .modal-header{
    span{
      font-weight: 300;
      font-size: 20px;
      color: #252525;
      font-family: "Raleway", sans-serif;
    }
    button{
      span{
        color: #99cc66 !important;
        font-family: auto;
        font-weight: 500;
        font-size: 25px;
      }
    }
  }
  #xcube-checkbox{
    .text-label{
      margin-left: 15px;
    }
    .checkbox-label{
      margin-left: 15px;
    }
  }
}
