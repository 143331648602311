#linkDialog{
  font-family: "Raleway", sans-serif;
  width: 40%;
  max-width: 40%;
  max-height: 60%;
  .share{
    margin: 10px 20px 0 0;
    font-size: 16px;
    color: #88c559;
    font-weight: 500;
    cursor: pointer;
  }
  .modal-body{
    background-color: #f5f5f5;
    padding: 30px 100px;
    p{
      color: #bfbfbe;
      font-size: 15px;
      text-align: center;
      margin-top: 10px;
    }
    img{
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    .input-field{
      width: 100%;
      margin-top: 25px;
      display: inline-flex;
      border: 1px dashed #aaaaaa;
      border-radius: 4px;
      padding: 10px;
      input{
        background-color: transparent;
        border: none;
        margin-left: 10px;
        color: #252525;
        font-size: 16px;
        width: 100%;
      }
    }
  }
  .modal-header{
    span{
      font-weight: 300;
      font-size: 20px;
      color: #252525;
      font-family: "Raleway", sans-serif;
    }
    small{
      color: #aaaaaa;
      font-size: 14px;
      line-height: 1.2;
    }
    button{
      span{
        color: #99cc66 !important;
        font-family: auto;
        font-weight: 500;
        font-size: 25px;
      }
    }
  }
  .modal-content{
    height: fit-content;
  }



  @media (max-width: 767px) {
    max-width: 100%;
    width: 95%;
    .modal-body {
      padding: 30px 6px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 63%;
    max-width: 80%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 63%;
    max-width: 80%;
  }

}
