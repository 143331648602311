#xCubeInputFilePdf{
  font-family: "Raleway", sans-serif;
  max-width: 100%;
  margin: 20px 20px 20px 0;
  input[type="file"] {
    display: none;
  }
  border: 1px solid #ced4db;
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
  border-radius: 4px;
  .xcube-file-upload-pdf{
    color: #8c8c8c;
    img{
      position: absolute;
      right: 50px;
      height: 31%;
    }
    width: 100%;
  }
  .xcube-input-label{
    transform: translate(0%, -90%);
    background-color: white;
    color: #8c8c8c;
    font-size: 12px;
    padding: 0 10px 0 10px;
    position: absolute;
  }
}
