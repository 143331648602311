#contact-about-page{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  background-position: bottom;
  .container {
    padding-top: 5rem;
    padding-bottom: 15rem;
    h5 {
      color: #707072;
      font-weight: 700;
    }
    #xcube-input {
      .xcube-input{
        background-color: #f3f3f3 !important;
      }
      .xcube-input-label{
        background-color: #f3f3f3 !important;
      }
    }
    #xcube-textarea {
      .xcube-textarea{
        background-color: #f3f3f3 !important;
      }
    }
    .hero-text {
      text-align: right;
      color: #707072;
      h3 {
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
      }
      p {
        margin-top: 1.5rem;
        font-size: 16px;
        color: rgb(140, 140, 140);
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
    }
  }
}