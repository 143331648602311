#why-brame-page-intro{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  background-size: 100% 54%;
  transform: translate(0px, -80px);
  .container{
    padding-top: 5rem;
    .intro-text{
      color: #707072;
      h3{
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 0;
      }
      p{
        margin-top: 1.5rem;
        font-size: 16px;
        color: rgb(140, 140, 140);
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
      span{
          font-size: 20px;
          font-family: "Arial";
          color: rgb(97, 97, 97);
          line-height: 1.5;
      }
      .intro-text-white{
        color: white;
        p{
          color: white;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    background-size: 530%;
    .intro-text{
      h3{
        margin-top: 1rem;
      }
    }
    .image-col{
      margin-top: 5rem;
    }
  }

  }