.page-title{
  color: white;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
}
#dashboard-page{
  background-color: white;
  .show-more{
    margin: 0 auto;
    display: table;
    color: #88c559;
    padding: 20px;
  }
  .allCampaignsBtn{
    #xcube-filled-button{
      float: right;
    }
  }
  #xCubeDatePicker{
    padding: 7px 0 0 0;
  }
  #GAAccountId{
    display: none;
  }
  .section-head {
    h1 {
      font-size: 26px;
      color: #252525;
      font-weight: 600;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
  }
  hr{
    border-top: 1px dashed #bfbfbe;
  }



  .table{
    th{
      font-size: 14px;
      color: #616161;
      font-weight: 700;
    }
    thead{
      tr{
        background-color: #ebebeb;
        th{
          border: none;
        }
      }
    }
    tbody{
      font-size: 18px;
      line-height: 17px;
      color: #616161;
      font-weight: 500;
      tr{
        border-bottom: 1px dashed #dddddd;
        td{
          img{
            max-width: 100px;
            max-height: 80px;
          }
          border-top: none;
          p{
            font-size: 14px;
            color: #aaaaaa;
            line-height: 2;
          }
          .options{
            display: none;
            margin-top: 15px;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 0;
            }
            a:last-child{
              color: #f78b60;

            }
          }
          .options2{
            display: none;
            margin-top: 15px;
            bottom: 10px;
            position: absolute;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 5px;
            }
          }
        }
      }
      tr:hover{
        filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
        background-color: #f5f5f5;
        border: 1px solid #ffffff;
        .options{
          //display: block;
          display: none;
          span{
            color: #f78b60;
          }
          img{
            margin-right: 5px;
          }
        }
        .options2{
          display: grid;
          //display: none;
          span{
            color: #f78b60;
          }
          img{
            margin-right: 5px;
          }
        }
      }
    }
  }
  .statistics-row{
    h2{
      font-size: 26px;
      color: #252525;
      font-weight: 600;
    }
    .export-btn{
      #xcube-filled-button{
        float:right;
      }
    }
    .select-row{
      #xCubeDatetimePicker{
        padding: 20px 0;
        .xcubeDateTimePicker{
          label{
            font-size: 12px;
          }
        }
      }
      #select {
        select{
          background-color: white !important;
          color: #252525;
        }
        .form-label{
          background-color: white !important;
          color: #8c8c8c;
        }
        .form-group{
          background-color: white !important;
        }
      }
    }
  }
  .pause{
    #xcube-text-button{
      float: right;
    }
  }
  .status {
    h1 {
      font-size: 26px;
      color: #252525;
      font-weight: 600;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
    span {
      font-size: 18px;
      line-height: 2;
      color: #aaaaaa;
      font-weight: 500;
      strong {
        color: #616161;
      }
    }
  }




  // izmena velicine slicice card-img

  @media (max-width: 767px) {
    .card-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28% ;
      margin-top: 35% ;
      margin-left: -40% ;
      // display: none;
    }


    .pause{
      #xcube-filled-button{
        float: left;
      }
    }

    .allCampaignsBtn{
      #xcube-filled-button{
        float: left ;
      }
    }


    // table

    .table{
      th{
        font-size: 14px;
        color: #616161;
        font-weight: 700;
      }
      thead{
        tr{
          background-color: #ebebeb;
          th{
            border: none;
          }
        }
      }
      tbody{
        font-size: 18px;
        line-height: 17px;
        color: #616161;
        font-weight: 500;
        tr{
          border-bottom: 1px dashed #dddddd;
          td{
            img{
              max-width: 100px;
              max-height: 80px;
            }
            border-top: none;
            p{
              font-size: 14px;
              color: #aaaaaa;
              line-height: 2;
            }
            .options{
              display: none;
              margin-top: 15px;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 0;
              }
              a:last-child{
                color: #f78b60;
              }
            }
            .options2{
              display: none;
              margin-top: 15px;
              bottom: 10px;
              position: absolute;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 5px;
              }
            }
          }
        }
        tr:hover{
          filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
          background-color: #f5f5f5;
          border: 1px solid #ffffff;
          .options{
            //display: grid;
            display: none;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            }
          }
          .options2{
            display: grid;
            //display: none;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            }
          }
        }
      }
    }



    #campaignStatusEngagement .card .card-title {
      font-size: 25px;
      color: #252525;
      font-weight: 300;
    }

    #dashboardConversions .card .card-title {
      font-size: 25px;
      color: #252525;
      font-weight: 300;
    }

    .tableXoverflow {
      overflow-x:auto;
    }

  }

  @media (min-width: 768px) and (max-width: 991px) {

    .pause{
      #xcube-filled-button{
        float: right;
      }
    }

    #dashboardConversions .card .card-title {
      font-size: 18px;
      color: #252525;
      font-weight: 300;
    }

    // table

    .table{
      th{
        font-size: 14px;
        color: #616161;
        font-weight: 700;
      }
      thead{
        tr{
          background-color: #ebebeb;
          th{
            border: none;
          }
        }
      }
      tbody{
        font-size: 18px;
        line-height: 17px;
        color: #616161;
        font-weight: 500;
        tr{
          border-bottom: 1px dashed #dddddd;
          td{
            img{
              max-width: 100px;
              max-height: 80px;
            }
            border-top: none;
            p{
              font-size: 14px;
              color: #aaaaaa;
              line-height: 2;
            }
            .options{
              display: none;
              margin-top: 15px;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 0;
              }
              a:last-child{
                color: #f78b60;
              }
            }
            .options2{
              display: none;
              margin-top: 15px;
              bottom: 10px;
              position: absolute;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 5px;
              }
            }
          }
        }
        tr:hover{
          filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
          background-color: #f5f5f5;
          border: 1px solid #ffffff;
          .options{
            //display: grid;
            display: none;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            }
          }
          .options2{
            display: grid;
            //display: none;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            }
          }
        }
      }
    }


  }

  .card-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin-top: -50% !important;
    margin-left: -40%;
  }


  @media (min-width: 992px) and (max-width: 1199px) {


    .pause{
      #xcube-filled-button{
        float: right;
      }
    }



    .allCampaignsBtn{
      #xcube-filled-button{
        float: right;
      }
    }

    .table{
      th{
        font-size: 14px;
        color: #616161;
        font-weight: 700;
      }
      thead{
        tr{
          background-color: #ebebeb;
          th{
            border: none;
          }
        }
      }
      tbody{
        font-size: 18px;
        line-height: 17px;
        color: #616161;
        font-weight: 500;
        tr{
          border-bottom: 1px dashed #dddddd;
          td{
            img{
              max-width: 100px;
              max-height: 80px;
            }
            border-top: none;
            p{
              font-size: 14px;
              color: #aaaaaa;
              line-height: 2;
            }
            .options{
              display: none;
              margin-top: 15px;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 0;
              }
              a:last-child{
                color: #f78b60;
              }
            }
            .options2{
              display: none;

              margin-top: 15px;
              bottom: 10px;
              position: absolute;
              a{
                font-size: 14px;
                font-weight: 500;
                color: #88c559;
                margin: 10px 10px 0 5px;
              }
            }
          }
        }
        tr:hover{
          filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
          background-color: #f5f5f5;
          border: 1px solid #ffffff;
          .options{
            //display: grid;
            display: none;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            }
          }
          .options2{
            display: grid;
            //display: none;
            span{
              color: #f78b60;
            }
            img{
              margin-right: 5px;
            }
          }
        }
      }
    }



  }

  @media(min-width: 1200px) {



    #xcube-filled-button{
      float: right;
    }


  }


}














