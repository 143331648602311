#hero-inspiration-page{
  background-image: url("./assets/bg.png");
  background-repeat: no-repeat;
  padding-top: 10rem;
  padding-bottom: 10rem;
  h3{
    font-size: 40.284px;
    color: rgb(252, 252, 255);
    text-transform: uppercase;
    line-height: 1;
    text-align: left;
    font-weight: 800;
  }
  p{
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 1.625;
    text-align: left;
  }
}