#checkOutSoftware-home-page{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  background-size: 100% 65%;
  background-position-y: 65%;
  .container{
    padding-top: 5rem;
    text-align: right;
    .intro-text{
      margin-top: 3rem;
      color: #707072;
      h3{
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
      }
      p{
        margin-top: 1.5rem;
        font-size: 16px;
        color: rgb(140, 140, 140);
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .container{
      padding-top: 1rem;
    }
  }
}