#gameInspiration-home-page{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  //background-size: 100% 65%;
  //background-position-y: 65%;
  padding-bottom: 3rem;
  .container-fluid{
    padding-left: 10%;
    padding-top: 5rem;
    text-align: right;
    .intro-text{
      margin-top: 3rem;
      color: white;
      text-align: left;
      h3{
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
      }
      p{
        font-weight: bold;
        margin-top: 1.5rem;
        font-size: 16px;
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
      #xcube-filled-button{
        button{
          font-size: 16px;
          color: rgb(92, 185, 145);
          font-weight: bold;
          line-height: 1.2;
          background: white;
          padding: 7px 20px;
        }
      }
    }
    .intro2-text{
      margin-top: 13rem;
      text-align: left;
      h3{
        font-size: 40px;
        text-transform: uppercase;
        color: rgb(112, 112, 114);
        line-height: 1.2;
        font-weight: 900;
      }
      p{
        font-size: 16px;
        color: rgb(140, 140, 140);
        line-height: 1.625;
        text-align: left;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .intro2-text{
      margin-top: 5em !important;
    }
    .mt-sm-3{
      margin-top: 5em;
    }
  }
}