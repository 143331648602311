
  #dashboard-devices{
    height: 100%;
    padding-bottom: 1.5rem; 
    .deviceImg{
      width: 100%;
      margin-top: 0px;
      margin-left: 15px;
      // margin-right: 15px;
    }
  
    .card{
      height: 100%;
      border: 1px dashed #aaaaaa;
      .card-title-devices {
        font-size: 26px;
        color: #8c8c8c;
        font-weight: 300;
        line-height: 1;
      }
      .card-text{
        line-height: 1.3;
        span {
          color: #88c559;
        }
        .strongVisitors{
          color: #8c8c8c;
          font-weight: 400;
          font-size: 32px;
        }
        small {
            font-size: 16px;
        }
        font-size: 20px;
        font-weight: 200;
        color: #8c8c8c;
        margin:0;
        .text-muted{
          font-size: 16px;
          color: #8c8c8c;
          font-weight: 500;
        }
      }
      .card-text-data{
        font-size: 56px;
        color: #88c559;
        font-weight: 300;
        line-height: 0.75;
      }
      .card-img{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        margin-top: -60%;
        margin-left: -40%;
      }
      .card-body-time {
        padding: 0px 0px 0px 1rem;
      }
    }


    @media (max-width: 320px) {
      .deviceImg {
        width: 80px;
        margin-top: 0px;
        margin-left: 15px;
      }
      .card {
        .card-text {
          font-size: 17px;
        }
      }
    }
    @media (min-width: 321px) and (max-width: 991px) {
      .deviceImg {
        width: 100px;
        margin-top: 0px;
        margin-left: 10px;
      }
      .card {
        .card-text {
          font-size: 18px;
          font-weight: 400;
        }
        .card-body {
          padding-top: 20px !important;
        }
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      .deviceImg {
        width: 90px;
        margin-top: 0px;
        margin-left: 10px;
      }
      .card {
        .card-text {
          font-size: 17px;
          small {
            font-size: 15px;
          }
        }
        
      }
    }

    @media (min-width: 1200px) {
      .deviceImg {
        width: 100px;
        margin-top: 0px;
        margin-left: 10px;
      }

      .card {
        .card-text {
          font-size: 17px;
          small {
            font-size: 15px;
          }
        }
        
      }
    }
  }

