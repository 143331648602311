#campaign-leads{
    height: 100%;
    padding-bottom: 1.5rem;
    .bestTimeImg{
      width: 100%;
      margin-top: 0px;
      margin-left: 10px;
    }
    .card{
      height: 100%;
      border: 1px dashed #aaaaaa;
      .card-title{
        font-size: 26px;
        color: #8c8c8c;
        font-weight: 300;
        line-height: 1;
      }
      .card-text{
        line-height: 1.3;
        .strongTime{
          color: #8c8c8c;
          font-weight: 400;
          font-size: 32px;
        }
        small {
            font-size: 16px;
        }
        font-size: 20px;
        font-weight: 200;
        color: #8c8c8c;
        margin:0;
        .text-muted{
          font-size: 16px;
          color: #8c8c8c;
          font-weight: 500;
        }
      }
      .card-text-data{
        font-size: 56px;
        color: #616161;
        font-weight: 300;
        line-height: 0.75;
      }
      .card-img{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        margin-top: -60%;
        margin-left: -40%;
      }
      .card-body-time {
        padding: 0px 0px 0px 1rem;
      }
    }
  }
  