#register-payment {
  background-image: url("./assets/white-bg.png");
  .backgroundGreen{
    background-image: url("./assets/BG.png");
    background-repeat: no-repeat;
  }
  .container-fluid {
    //width: 75%;
    color: white;
    padding-top: 7rem;
    padding-left: 8.5rem;
    padding-right: 7rem;

    .hero-text {
      margin-bottom: 8rem;
      text-align: left;

      h1 {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 40.284px;
        line-height: 1.2;
      }

      p {
        font-weight: 500;
        margin-top: 1.5rem;
        font-size: 16px;
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
    }
    #xcube-checkbox {
      padding: 20px 60px 20px 0;
      .custom-checkbox {
        .custom-control-label::before{
          background: none !important;
          border-color: #aaaaaa;
        }
      }
    }
    #xcube-input{
      width: 100%;
      .xcube-input-label{
        background-color: #f4f4f4 !important;
        color: #8c8c8c;
        font-size: 12px;
      }
      input{
        background-color: #f4f4f4 !important;
        //border: 1px solid #8c8c8c;
      }
    }
    hr{
      border-top: 1px dashed #bfbfbe;
    }
    #xcube-filled-button{
      padding: 15px;
    }
    #select{
      padding: 20px 20px 20px 0;
      width: 100%;
      .xcube-input-label{
        background-color: #f4f4f4
      }
      .form-group{
        background-color: #f4f4f4 !important;
        .form-label{
          background-color: #f4f4f4;
          color: #8c8c8c;
          font-weight: 400;
        }
        select{
          background-color: #f4f4f4 !important;
          color: #252525;
          font-size: 1rem;
        }
      }
    }
    .section-head{
      h1 {
        font-size: 30px;
        color: #252525;
        font-weight: 300;
      }
      h2 {
        font-size: 20px;
        line-height: 18px;
        color: #616161;
        font-weight: 500;
      }
      p {
        font-size: 14px;
        line-height: 18px;
        color: #aaaaaa;
        font-weight: 500;
      }
      span{
        color: #616161;
        font-size: 18px;
        font-weight: 500;
        line-height: 2;
      }
      a{
        color: #88c559;
      }
    }
    .publish{
      hr{
        border-top: 1px dashed #bfbfbe;
      }
      #xcube-filled-button{
        transform: translate(0px, -48px);
        button{
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
      .btn-group{
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .container-fluid {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      padding-top: 5rem;
    }
    background-position-x: 100% !important;
  }
}
