#campaign-leads{
    height: 100%;
    padding-bottom: 1.5rem;
    img{
      width: 100%;
      margin-top: 20px;
      margin-left: 10px;
    }
    .card{
      height: 100%;
      border: 1px dashed #aaaaaa;
      .card-title{
        font-size: 26px;
        color: #616161;
        font-weight: 300;
        line-height: 1;
      }
      .card-text{
        line-height: 1.3;
        strong{
          color: #88c559;
          font-weight: normal;
          font-size: 16px;
        }
        font-size: 15px !important;
        font-weight: 200;
        color: #8c8c8c;
        margin:0;
        .text-muted{
          font-size: 16px;
          color: #8c8c8c;
          font-weight: 500;
        }
      }
      .card-text-data{
        font-size: 56px;
        color: #616161;
        font-weight: 300;
        line-height: 0.75;
      }
      .card-img{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        margin-top: -60%;
        margin-left: -40%;
      }
      .card-body-game {
        padding: 0px 0px 0px 1rem;
      }
    }
  }
