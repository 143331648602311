#createCampaignWizard{
  //background-image: url("./assets/BG.svg");
  background-size: cover;
  font-family: "Raleway", sans-serif;
  min-height: 100vh;
  #GAAccountId{
    display: none;
  }
}
.rsw_2f{
  display: none;
}
.rsw_3G{
  display: block;
}

@media (max-width: 767px) {
  #createCampaignWizard {
    .rc-steps {
      display: none;
    }
    #createCampaignWizardStart {
      #xcube-filled-button {
        button {
          margin: 5px;
        }
      }
    
    }
    
  }

  


}

@media (min-width: 768px) and (max-width: 991px) {
  #createCampaignWizard {
    .rc-steps {
      display: none;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #createCampaignWizard {
    .rc-steps {
      display: flex;
      width: 100% !important;
    }
  }
}