@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700,900&display=swap');
/*body{*/
    /*background-color: #252525 !important;*/
/*}*/

.xcube-link{
    color: #99cc66;
}

.xcube-link:hover{
    color: #99cc66;
    opacity: .5;
    text-decoration: none;
}

.xcube-badge{
    color: white;
    border-radius: 1px;
    background-color: #81b151;
}

.Toastify__toast--success{
    background-image: linear-gradient(45deg, #57ad63 0%, #cbc046 100%) !important;
}

