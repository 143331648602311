#register-plan{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  .container-fluid{
    //width: 75%;
    text-align: center;
    color: white;
    padding-top: 7rem;
    padding-left: 8.5rem;
    padding-right: 7rem;
    .hero-text{
      margin-bottom: 8rem;
      text-align: left;
      h1{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 40.284px;
        line-height: 1.2;
      }
      p{
        font-weight: 500;
        margin-top: 1.5rem;
        font-size: 16px;
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
    }
  }
  .main-card{
    border: 1px dashed #bfbfbe;
    .card{
      border: none;
      background: #ffffff;
      color: #8c8c8c;
    }
  }
  .main-card-active{
    border: none;
    background-color: #f9f9f9 !important;
    box-shadow: 0 0 64px -40px rgba(0,0,0,0.75);
  }
  .card{
    background-color: #f3f3f3;
    .second-body{
      .card-text{
        color: #8c8c8c;
        font-size: 14px;
        margin: 0;
        font-weight: 500;
        small{
          color: #cacaca !important;
          cursor: pointer;
        }
      }
    }
  }
  .publish{
    hr{
      border-top: 1px dashed #bfbfbe;
    }
    #xcube-filled-button{
      transform: translate(0px, -30px);
      button{
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .container-fluid {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
