

.section-head{
  h1 {
    font-size: 30px;
    color: #252525;
    font-weight: 300;
  }
  h2 {
    font-size: 20px;
    line-height: 18px;
    color: #616161;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #aaaaaa;
    font-weight: 500;
  }
}
#campaignGeneral{
  background-color: white;
  #select{
    padding: 20px 20px 20px 0;
    width: 100%;
    .form-group{
      background-color: white !important;
      .form-label{
        background-color: white;
        color: #8c8c8c;
        font-weight: 400;
      }
      select{
        background-color: white !important;
        color: #252525;
        font-size: 1rem;
      }
    }
  }
  #xcube-input{
    width: 100%;
    .xcube-input-label{
      background-color: white !important;
      color: #8c8c8c;
      font-size: 12px;
    }
    input{
      background-color: white !important;
      //border: 1px solid #8c8c8c;
    }
  }
  .edit-page-btn{
    #xcube-filled-button{
      float: right;
    }
  }
  .btn-group-basics{
    width: 100%;
  }
  hr{
    border-top: 1px dashed #bfbfbe;
  }
  .btn-group-brand{
    #xCubeInputFile{
      height: calc(2.5em + .75rem);
      margin: 20px 0 0 0;
      padding: .875rem .75rem;
      .xcube-input-label{
        transform: translate(0%, -115%);
      }
    }
    .color-picker{
      display: block;
      border-radius: 4px;
      border: 1px solid #ced4dc;
      margin: 20px 20px 3px 0;
      max-width: 100%;
      padding: 0.275rem .75rem 0 .75rem;
      .secondaryColorPicker, .primaryColorPicker{
        display: inline-flex;
        p{
          font-size: 14px;
          margin-right: 0.5rem;
          margin-bottom: 0;
          color: #8c8c8c;
        }
      }
      .secondaryColorPicker{
        float: right;
        margin-top: 2px;
      }
    }
  }
  .edit-page{
    line-height: 2;
    font-size: 16px;
    color: #88c559;
    font-weight: 500;
  }
  .privacy-terms{
    #xcube-textarea {
      .xcube-textarea{
        background-color: white;
      }
      .xcube-textarea-label {
        background-color: white;
      }
    }
    .react-inputs-validation__select__options-container___d54qE{
      z-index: 2;
    }
  }
  .preview-link{
    color: #88c559;
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .primaryColorPicker {
    display: inline-grid !important;
  }

  .secondaryColorPicker {
    display: inline-grid !important;
  }
}
 
@media (max-width: 767px) {
  .section-head {
    margin-top: 10px;
  }

  .changes {
    padding: 15px
  }

}

@media(min-width: 768px) and (max-width: 991px) {
  .primaryColorPicker {
    display: inline-grid !important;
  }

  .secondaryColorPicker {
    display: inline-grid !important;
  }

  #campaignGeneral {
    .edit-page {
      font-size: 14px;
    }
  }
}

@media(min-width: 992px) and (max-width: 1199px) {
  .primaryColorPicker {
    display: inline-grid !important;
  }

  .secondaryColorPicker {
    display: inline-grid !important;
  }
}