#interactive-why-brame{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  padding-bottom: 10rem;
  padding-top: 5rem;
  h3{
    font-size: 55px;
    color: rgba(255, 255, 255, 0.749);
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    font-weight: 800;
    margin: 0;
  }
  h1{
    font-size: 200px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 0.9;
    text-align: center;
    font-weight: 800;
  }
  a{
    float: right;
    margin-right: 4.2rem;
    color: white;
    font-size: 15px;
  }
  @media only screen and (max-width: 600px) {
    h1{
      font-size: 100px;
    }
    background-size: 610%;
  }
}