#seeAllCampaigns{
    max-width: 80%;
    .modal-content{
        height: fit-content;
        }
        .modal-body{
        background-color: #f5f5f5;
        .xcube-input-label{
            background-color: #f5f5f5 !important;
        }
        }
        .modal-header{
        span{
            font-weight: 300;
            font-size: 20px;
            color: #252525;
            font-family: "Raleway", sans-serif;
        }
        button{
            span{
            color: #99cc66 !important;
            font-family: auto;
            font-weight: 500;
            font-size: 25px;
            }
        }
    }
    .table{
        th{
          font-size: 14px;
          color: #616161;
          font-weight: 700;
        }
        thead{
          tr{
            background-color: #ebebeb;
            th{
              border: none;
            }
          }
        }
        tbody{
          font-size: 18px;
          line-height: 17px;
          color: #616161;
          font-weight: 500;
          tr{
            border-bottom: 1px dashed #dddddd;
            td{
              border-top: none;
              img{
                max-width: 100px;
                max-height: 80px;
              }
              p{
                font-size: 14px;
                color: #aaaaaa;
                line-height: 2;
              }
              .options{
                display: none;
                margin-top: 15px;
                a{
                  font-size: 14px;
                  font-weight: 500;
                  color: #88c559;
                  margin: 10px 10px 0 0;
                }
                a:last-child{
                  color: #f78b60;
                }
              }
              .options2{
                display: none;
                margin-top: 15px;
                bottom: 10px;
                position: absolute;
                a{
                  font-size: 14px;
                  font-weight: 500;
                  color: #88c559;
                  margin: 10px 10px 0 5px;
                }
              }
            }
          }
          tr:hover{
            filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
            background-color: #f5f5f5;
            border: 1px solid #ffffff;
            .options, .options2{
              display: block;
              span{
                color: #f78b60;
              }
              img{
                margin-right: 5px;
              }
            }
          }
        }
      }
}