#xcube-analytics-question-details{
  .toggle-button{
    background: transparent;
    border: none;
  }
  .card-header{
    background-color: rgba(0,0,0,.01);
  }
  td{
    padding: 15px;
    color: #8c8c8c;
    border-top: none !important;
    font-size: 14px !important;
  }
  tr{
    th{
      border-top: none;
      border-bottom: 1px dashed #dee2e6 !important;
    }
    th:nth-of-type(2) {
      text-align: center;
    }
    th:nth-of-type(3) {
      text-align: center;
    }
    td:nth-of-type(2) {
      text-align: center;
    }
    td:nth-of-type(3) {
      text-align: center;
    }
  }
  th{
    color: #8c8c8c;
  }

  @media(max-width: 991px) {
    width: 100% !important;
    margin: 0 auto;
  }
}
