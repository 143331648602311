#createCampaignWizardExpressBrand{
  .card{
      background-color: #f5f5f5;
      .card-body{
        .head{
          h3{
            font-size: 30px;
            color: #252525;
            font-weight: 300;
            text-align: center;
            margin-top: 30px;
          }
          p{
            font-size: 20px;
            color: #252525;
            font-weight: 300;
            text-align: center;
            margin-bottom: 0;
          }
          small{
            font-size: 14px;
            color: #aaaaaa;
            font-weight: 500;
            text-align: center;
            display: block;
            width: 60%;
            margin-left: auto;
            margin-right: auto;
          }
        }
        img{
          margin-left: auto;
          margin-right: auto;
          display: block;
          max-width: 100px;
        }

        form{
          display: block;
          border-radius: 4px;
          border: 0.5px solid #2c2c2c;
          padding: 15px 20px 3px 20px;
          width: 80%;
          margin: 3rem auto 2rem auto;
          .secondaryColorPicker, .primaryColorPicker{
            display: inline-flex;
            p{
              margin-right: 1rem;
            }
          }
          .secondaryColorPicker{
            float: right;
          }
        }


        #xcube-filled-button {
          button{
            float: right;
            margin: 30px;
          }
        }

        #xcube-text-button{
          button{
            float: right;
            margin: 25px 30px 30px 30px;
          }
        }
        #xcube-file-upload {
          border-radius: 4px;
          border: 0.5px solid #2c2c2c;
          padding: 15px 20px 3px 20px;
          width: 77%;
          margin: 0 auto 2rem auto;
          .container-fluid{
            background-color: transparent;
            border-color: transparent;
            h3{
              font-size: 20px;
              color: #252525;
              font-weight: 300;
            }
            p{
              line-height: 18px;
              color: #aaaaaa;
            }
          }
        }

       

      }

      @media (max-width: 320px) {

        .card-body {
          form {
            width: 100%;
            display: grid !important;
            .primaryColorPicker {
              display: block !important;
            }
            .secondaryColorPicker {
              display: block !important;
            }
          }
        }

        .btn-group{
          transform: translateX(10%);
          max-width: 100%;
          width: 310px;
          // display: none;
          #xcube-filled-button {
            width: 140px;
            #filledButton {
              width: 120px;
            }
          }
          #xcube-text-button {
            width: 108px;
            .normal {
              width: 120px;
            }
          }
        }
  
      }

      @media (min-width: 321px) and (max-width: 767px) {
        .card-body {
          form {
            width: 100%;
            display: grid !important;
          }
        }

        .btn-group{
          transform: translateX(10%);
          max-width: 100%;
          width: 310px;
          // display: none;
          #xcube-filled-button {
            width: 140px;
            #filledButton {
              width: 120px;
            }
          }
          #xcube-text-button {
            width: 108px;
            .normal {
              width: 120px;
            }
          }
        }

      }

     

     
  }
}
