@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Roboto Slab',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Roboto Slab',
    monospace;
}


/* icons  */

@font-face {
  font-family: 'Brame-Icons';
  src:
    url('./fonts/Brame-Icons.ttf?5bkodo') format('truetype'),
    url('./fonts/Brame-Icons.woff?5bkodo') format('woff'),
    url('./fonts/Brame-Icons.svg?5bkodo#Brame-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Brame-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-add:before {
  content: "\e900";
  color: #88c559;
}
.icn-addelement:before {
  content: "\e901";
  color: #88c559;
}
.icn-bold:before {
  content: "\e902";
  color: #88c559;
}
.icn-brand:before {
  content: "\e903";
  color: #88c559;
}
.icn-campaigns:before {
  content: "\e904";
  color: #88c559;
}
.icn-cancel:before {
  content: "\e905";
  color: #88c559;
}
.icn-card:before {
  content: "\e906";
  color: #88c559;
}
.icn-chat:before {
  content: "\e907";
  color: #88c559;
}
.icn-code:before {
  content: "\e908";
  color: #88c559;
}
.icn-config:before {
  content: "\e909";
  color: #88c559;
}
.icn-confirm:before {
  content: "\e90a";
  color: #88c559;
}
.icn-content:before {
  content: "\e90b";
  color: #88c559;
}
.icn-conversion:before {
  content: "\e90c";
  color: #88c559;
}
.icn-copy:before {
  content: "\e90d";
  color: #88c559;
}
.icn-dashboard:before {
  content: "\e90e";
  color: #88c559;
}
.icn-delete:before {
  content: "\e90f";
  color: #88c559;
}
.icn-design:before {
  content: "\e910";
  color: #88c559;
}
.icn-desktop:before {
  content: "\e911";
  color: #88c559;
}
.icn-edgescale:before {
  content: "\e912";
  color: #88c559;
}
.icn-edit:before {
  content: "\e913";
  color: #88c559;
}
.icn-email:before {
  content: "\e914";
  color: #88c559;
}
.icn-entrice:before {
  content: "\e915";
  color: #88c559;
}
.icn-export:before {
  content: "\e916";
  color: #88c559;
}
.icn-facebook:before {
  content: "\e917";
  color: #88c559;
}
.icn-finish:before {
  content: "\e918";
  color: #88c559;
}
.icn-fontsize:before {
  content: "\e919";
  color: #88c559;
}
.icn-fullscreen:before {
  content: "\e91a";
  color: #88c559;
}
.icn-gamepad:before {
  content: "\e91b";
  color: #88c559;
}
.icn-grid:before {
  content: "\e91c";
  color: #88c559;
}
.icn-hash:before {
  content: "\e91d";
  color: #88c559;
}
.icn-help:before {
  content: "\e91e";
  color: #88c559;
}
.icn-home:before {
  content: "\e91f";
  color: #88c559;
}
.icn-import:before {
  content: "\e920";
  color: #88c559;
}
.icn-info:before {
  content: "\e921";
  color: #88c559;
}
.icn-instagram:before {
  content: "\e922";
  color: #88c559;
}
.icn-integration:before {
  content: "\e923";
  color: #88c559;
}
.icn-italic:before {
  content: "\e924";
  color: #88c559;
}
.icn-lead:before {
  content: "\e925";
  color: #88c559;
}
.icn-legal:before {
  content: "\e926";
  color: #88c559;
}
.icn-link:before {
  content: "\e927";
  color: #88c559;
}
.icn-locked:before {
  content: "\e928";
  color: #88c559;
}
.icn-logout:before {
  content: "\e929";
  color: #88c559;
}
.icn-loyalty:before {
  content: "\e92a";
  color: #88c559;
}
.icn-media:before {
  content: "\e92b";
  color: #88c559;
}
.icn-menu:before {
  content: "\e92c";
  color: #88c559;
}
.icn-more:before {
  content: "\e92d";
  color: #88c559;
}
.icn-move:before {
  content: "\e92e";
  color: #88c559;
}
.icn-move-vertical:before {
  content: "\e92f";
  color: #88c559;
}
.icn-next:before {
  content: "\e930";
  color: #88c559;
}
.icn-notification:before {
  content: "\e931";
  color: #88c559;
}
.icn-page:before {
  content: "\e932";
  color: #88c559;
}
.icn-pause:before {
  content: "\e933";
  color: #88c559;
}
.icn-person:before {
  content: "\e934";
  color: #88c559;
}
.icn-mobile:before {
  content: "\e935";
  color: #88c559;
}
.icn-phoneno:before {
  content: "\e936";
  color: #88c559;
}
.icn-play:before {
  content: "\e937";
  color: #88c559;
}
.icn-preview:before {
  content: "\e938";
  color: #88c559;
}
.icn-previous:before {
  content: "\e939";
  color: #88c559;
}
.icn-prize:before {
  content: "\e93a";
  color: #88c559;
}
.icn-publish:before {
  content: "\e93b";
  color: #88c559;
}
.icn-reload:before {
  content: "\e93c";
  color: #88c559;
}
.icn-save:before {
  content: "\e93d";
  color: #88c559;
}
.icn-screen:before {
  content: "\e93e";
  color: #88c559;
}
.icn-search:before {
  content: "\e93f";
  color: #88c559;
}
.icn-share:before {
  content: "\e940";
  color: #88c559;
}
.icn-shift:before {
  content: "\e941";
  color: #88c559;
}
.icn-size:before {
  content: "\e942";
  color: #88c559;
}
.icn-socialseo:before {
  content: "\e943";
  color: #88c559;
}
.icn-start:before {
  content: "\e944";
  color: #88c559;
}
.icn-stats:before {
  content: "\e945";
  color: #88c559;
}
.icn-stop:before {
  content: "\e946";
  color: #88c559;
}
.icn-tablet:before {
  content: "\e947";
  color: #88c559;
}
.icn-target:before {
  content: "\e948";
  color: #88c559;
}
.icn-team:before {
  content: "\e949";
  color: #88c559;
}
.icn-template:before {
  content: "\e94a";
  color: #88c559;
}
.icn-text:before {
  content: "\e94b";
  color: #88c559;
}
.icn-textalign-center:before {
  content: "\e94c";
  color: #88c559;
}
.icn-textalign-left:before {
  content: "\e94d";
  color: #88c559;
}
.icn-textalign-right:before {
  content: "\e94e";
  color: #88c559;
}
.icn-time:before {
  content: "\e94f";
  color: #88c559;
}
.icn-twitter:before {
  content: "\e950";
  color: #88c559;
}
.icn-underline:before {
  content: "\e951";
  color: #88c559;
}
.icn-undo:before {
  content: "\e952";
  color: #88c559;
}
.icn-warning:before {
  content: "\e953";
  color: #88c559;
}
.icn-website:before {
  content: "\e954";
  color: #88c559;
}
.icn-world:before {
  content: "\e955";
  color: #88c559;
}
.icn-youtube:before {
  content: "\e956";
  color: #88c559;
}
