#xCubeDatePicker{
  font-family: "Raleway", sans-serif;
  padding: 20px 20px 20px 0;
  .xcubeDatePicker{
    border: 1px solid #ced4db;
    border-radius: 4px;
    padding: 6px 10px 5px 20px; 
    label{
      position: absolute;
      transform: translate(-10px, -17px);
      background-color: white;
      padding: 0 10px;
      margin: 0;
      font-size: 12px;
      color: #8c8c8c;
      font-weight: 400;
    }
    .dateLabel{
      color: #252525;
    }
    .datePicker{
      width: 100%;
      // border: 1px solid red;
      .react-date-picker__wrapper{
        border: none;
        .react-date-picker__inputGroup__input{
          color: #88c559;
        }
      }
    }
    .react-date-picker__inputGroup__divider{
      color: #8c8c8c;
    }
  }
}
