#campaignPublishing{
  background-color: white;
    #xCubeDatetimePicker{
      label {
        font-size: 12px;
      }

    }
  .startDatePicker{
    span{
      font-weight: 300 !important;
    }
  }
  #xcube-filled-button{
    button{
      font-size: 14px !important;
      padding: 5px 30px !important;
    }
  }
  .btn-group-basics{
    width: 100%;
  }
  .btn-group{
    div{
      margin: 10px 20px 0 0;
    }
  }
  hr{
    border-top: 1px dashed #bfbfbe;
  }
  .section-head{
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
    span{
      color: #616161;
      font-weight: 500;
    }
    #select{
      padding: 0;
      .form-label{
        background-color: white;
      }
    }
  }
  #xcube-input{
    width: 100%;
    .background-image{
      background-image: none !important;
    }
    .xcube-input-label{
      background-color: white !important;
      color: #8c8c8c;
      font-size: 12px;
    }
    input{
      background-color: white !important;
      border: 1px solid #8c8c8c;
    }
  }
  .preview, .share{
    margin: 10px 20px 0 0;
    font-size: 16px;
    color: #88c559;
    font-weight: 500;
    cursor: pointer;
  }
  .main-card{
    border: 1px dashed #bfbfbe;
    .card{
      border: none;
      background: #f8f8f8;
    }
  }
  .main-card-text{
    font-size: 18px;
    color: #616161;
  }
  .card{
    .second-body{
      .card-text{
        color: #8c8c8c;
        font-size: 14px;
        small{
          color: #cacaca !important;
          cursor: pointer;
        }
      }
    }
  }
  .publish{
    #xcube-filled-button{
      transform: translate(0px, -30px);
      button{
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }

  @media (max-width: 767px) {
    .startDatePicker {
      margin-top: 15px;
    }

    #xcube-filled-button {
      margin-top: 15px;
      
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    #xcube-filled-button{
      button{
        font-size: 12px !important;
        padding: 5px 30px !important;
      }
    }

  }

  

}