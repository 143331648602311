
.playersTime {
  width: 60% !important;
  margin: 0 auto;
  td {
    padding: 0.25rem !important;
    color: #8c8c8c;
    border-top: none !important;
    font-size: 14px !important;
  }
  tr {
    th {
      border-top: none;
      border-bottom: 1px dashed #dee2e6 !important;
    }
    th:nth-of-type(2) {
      text-align: center;
    }
    th:nth-of-type(3) {
      text-align: center;
    }
  }
  th {
    color: #8c8c8c;

  }

  @media(max-width: 991px) {
    width: 100% !important;
    margin: 0 auto;
  }

}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
}


