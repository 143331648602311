#campaign-navbar{
  .nav-tabs {
    border: none;
    .nav-link{
      color: white;
    }
    .nav-link.active{
      color: #8c8c8c;
    }
  }
  .page-title{
    font-size: 36px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    margin: 20px 0 50px 0;
  }

  @media (max-width: 767px) {
   .page-title {
      font-size: 30px;
      color: #ffffff;
      font-weight: 700;
      text-align: center;
      margin: 20px 0 25px 0;
   }

    
    .stats {
      display: none;
    }
   
    .dropdownLinks .btn-success {
      background-color: rgba(0,0,0,0.1) !important;
      font-weight: 500;
      width: fit-content;
    }

    .dropdownLinks .dropdown-menu {
      width: inherit;
    }

    .dropdownLinks .dropdown-item a {
      color: #616161 !important;
    }

    .dropdownLinks .dropdown-item {
      padding: 0.5rem 1.5rem;
    }
   
  }

  @media (min-width: 768px) {
    .dropdownLinks {
      display: none;
    }
  }
}