#dashboardAccountCompanies{
  background-color: white;
  padding-bottom: 40px;
  .section-head {
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
    span {
      color: #616161;
      font-size: 18px;
      font-weight: 500;
      line-height: 2;
    }
    a {
      color: #88c559;
    }
  }
  .card{
    height: 100%;
    border: 1px dashed #aaaaaa;
    text-align: center;
    padding-bottom: 20px;
    .card-img{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      margin-top: -50%;
      margin-left: -40%;
    }
    .card-title{
      color: #616161;
      font-size: 18px;
    }
    .card-text{
      color: #616161;
      font-size: 14px;
      margin: 0;
    }
    span{
      color: #88c559;
      font-size: 12px;
      cursor: pointer;
    }
    .card-img-top{
      border-radius: 50%;
      width: 150px;
      height: 150px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    p{
      color: #cacaca;
      font-size: 12px;
      margin: 2rem 2rem 0.5rem 2rem;
    }
  }
  .card:hover{
    filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
    border: 1px solid #ffffff;
    cursor: pointer;
  }
}
