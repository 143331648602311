#culture-about-page{
  background-image: url("./assets/BG.png");
  background-repeat: no-repeat;
  .container{
    padding-top: 5rem;
    h5{
      color: #707072;
      font-weight: 700;
    }
    .hero-text{
      text-align: center;
      color: #707072;
      h3{
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
      }
      p{
        margin-top: 1.5rem;
        font-size: 16px;
        color: rgb(140, 140, 140);
        line-height: 1.625;
        margin-bottom: 1.5rem;
      }
    }
    .card-culture{
       p{
         font-size: 100px;
         color: rgba(140, 140, 140, 0.149);
         text-transform: uppercase;
         line-height: 0.267;
         font-weight: 800;
         margin: 0;
       }
      .card-text-right{
        text-align: right;
      }
      .card-text-center{
        text-align: center;
      }
    }
  }
}