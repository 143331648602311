#community-home-page{
  .container{
    padding-top: 5rem;
    text-align: center;
    .hero-text{
      margin-top: 5rem;
      h1{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 40.284px;
        color: rgb(112, 112, 114);
        line-height: 1.2;
        text-align: center;
      }
      p{
        margin-top: 1.5rem;
        font-size: 16px;
        color: rgb(140, 140, 140);
        line-height: 1.625;
        margin-bottom: 1.5rem;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .container {
      padding-top: 1rem;
    }
  }
}