#engagement-why-brame-page{
  h3{
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 900;
    color: #616161;

  }
  .parent-div{
    background-image: url("./assets/BG.png");
    background-size: cover;
    .hero-text{
      text-align: center;
      margin: 10em 0;
      h4{
        color: #616161;
        font-size: 24px;
      }
      h1{
        color: #616161;
        font-size: 100px;
        font-weight: 900;
        line-height: 0.9;
        padding: 0 0 30px 0;
        text-transform: uppercase;
        span{
          background: linear-gradient(to right, #58ad64, #cbc047 70%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  #banner-carousel{
    margin-left: auto;
    margin-right: auto;
    background-color: #616161;
    border-radius: 5px;
    transform: translate(0, -70px);
    .carousel-item{
      a{
        font-size: 12px;
        color: #ababab;
        line-height: 2;
        position: absolute;
        right: 15px;
        bottom: 10px;
      }
      .carousel-item-text{
        font-size: 20px;
        color: rgb(238, 238, 238);
        font-weight: bold;
        line-height: 1.231;
        padding: 20px 80px;
        h1{
          font-size: 100.278px;
          color: rgb(140, 140, 140);
          text-transform: uppercase;
          line-height: 1;
          font-weight: 800;
          background: linear-gradient(to right, #58ad64, #cbc047 80%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          float: right;
          margin-right: 4rem;
        }
        p{
          float: left;
          margin-top: 2rem;
        }
      }
    }
    .active{
      text-shadow: none;
    }
    .carousel-indicators{
      right: inherit;
      margin: 15px 30px;
      li{
        border-radius: 50%;
        width: 8px;
        height: 8px;
        border: 3px solid #ababab;
        background: none;
        margin-right: 0.8rem;
      }
      .active{
        background: #ababab;
      }
    }
  }
  #help-carousel{
    .carousel-indicators{
      left: inherit;
      margin: 15px 0 15px 30px;
      li{
        border-radius: 50%;
        width: 8px;
        height: 8px;
        border: 3px solid #7c7c7c;
        background: none;
        margin-right: 0.8rem;
      }
      .active{
        background: #7c7c7c;
      }
    }
    .carousel-inner{
      padding-bottom: 2rem;
      .carousel-item{
        text-align: right;
        text-shadow: none;
        h1{
          font-size: 150px;
          color: rgba(140, 140, 140, 0.149);
          text-transform: uppercase;
          line-height: .367;
          text-align: right;
          padding-top: 11px;
          font-weight: 900;
        }
        h5{
          font-size: 20px;
          color: rgb(97, 97, 97);
          font-weight: bold;
          line-height: 1.231;
          text-align: right;
        }
        p{
          font-size: 15px;
          color: rgb(97, 97, 97);
          line-height: 1.625;
          text-align: right;
        }
      }
    }

  }


  @media only screen and (max-width: 600px) {
    .hero-text {
      margin: 10em 0;
      h1{
        font-size: 40px !important;
      }
    }
    h3{
      font-size: 47px !important;
    }
    .carousel-item-text{
      padding: 20px 40px !important;
    }
  }
}