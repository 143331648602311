#xcube-file-upload{
  .thumbnails{
    flex: 0 0 25%;
    max-width: 25%;
  }
  width: 80%;
  .container-fluid{
    border-radius: 4px;
    background-color: #252525;
    border: dashed 1px #8c8c8c;
    padding: 20px 10px 10px 20px;
    h3{
      color: #f5f5f5;
      font-weight: 300;
      font-size: 18px;
    }
    p{
      font-size: 14px;
      line-height: 18px;
      color: #616161;
      max-width: 80%;
    }
    .upload-image{
      margin-left: auto;
      display: block;
    }
    svg{
      //.shp0 {fill: #99cc66;}

      .shp1 {
        fill: #99cc66;
      }
      .shp2 {
        opacity: 0.251;fill: #99cc66
      }
      .shp3 {
        opacity: 0.502;fill: #99cc66
      }
      .shp4 {
        fill: #99cc66
      }
    }
  }

}
