#mainIntegration{
  .card{
    background-color: transparent;
    border: 1px dashed #aaaaaa;
    text-align: center;
    .card-img{
      width: 70px;
    }
    .card-text{
      color: #8c8c8c;
      font-size: 12px;
    }
    .card-title{
      color: #616161;
      font-size: 16px;
    }
    .card-img-col{
      margin: 30px auto 10px auto;
    }
  }
}