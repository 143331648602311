#campaignCountry{
  height: 100%;
  padding-bottom: 1.5rem;
  .card{
    height: 100%;
    border: 1px dashed #aaaaaa;
    .card-title{
      font-size: 28px;
      color: #8c8c8c;
      font-weight: 300;
    }
    .card-text{
      line-height: 1.3;
      strong{
        color: #88c559;
        font-weight: normal;
      }
      font-size: 18px;
      font-weight: 300;
      color: #8c8c8c;
      margin:0;
      .text-muted{
        font-size: 16px;
        color: #8c8c8c;
        font-weight: 500;
      }
    }
    .card-text-data{
      font-size: 56px;
      color: #616161;
      font-weight: 300;
      line-height: 0.75;
    }
    .card-img{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      margin-top: -50%;
      margin-left: -40%;
    }

    .card-body {
      color: #8c8c8c;
    }
  }




}
