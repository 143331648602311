
#newPrizeWizard{
  position: absolute;
  top: 15px;
  right: 20px;
  margin: 0 !important;
  color: white !important;
}
#newPrizeWizardModal{
  .modal-content{
    height: fit-content;
  }
  .modal-body{
    background-color: #f5f5f5;
    .xcube-input-label{
      background-color: #f5f5f5 !important;
    }
    #select {
      .form-group {
        .form-label{
          background-color: #f5f5f5 !important;
        }
        select{
          background-color: #f5f5f5 !important;
        }
      }
    }
    #xCubeDatePicker{
      input{
        background-color: transparent;
      }
      label{
        background-color: #f5f5f5 !important;
      }
      .form-control{
        background-color: #f5f5f5 !important;
      }
    }
  }
  .modal-header{
    span{
      font-weight: 300;
      font-size: 20px;
      color: #252525;
      font-family: "Raleway", sans-serif;
    }
    button{
      span{
        color: #99cc66 !important;
        font-family: auto;
        font-weight: 500;
        font-size: 25px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {

    .modal-body {
      #xCubeInputFile{
      
          font-size: 13px;
        
      }
    }
    
  }


}

