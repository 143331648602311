#book-a-demo{
  background-image: url("assets/bg.png");
  background-repeat: no-repeat;
  background-color: #f3f3f3 !important;
  //padding-bottom: 3rem;
  .container-fluid{
    /*background-image: url("assets/green.png");
    background-repeat: no-repeat;
    background-size: 100% 40%;*/
    #xcube-input {
      .xcube-input{
        background-color: #f3f3f3 !important;
      }
      .xcube-input-label{
        background-color: #f3f3f3 !important;
      }
    }
    #xcube-textarea {
      .xcube-textarea{
        background-color: #f3f3f3 !important;
      }
    }
    text-align: center;
    color: white;
    padding-top: 7rem;
    padding-left: 8.5rem;
    padding-right: 7rem;
    .hero-text{
      //margin-bottom: 12rem;
      margin-bottom: 4rem;
      text-align: left;
      h1{
        text-transform: uppercase;
        font-weight: 900;
        font-size: 40.284px;
        line-height: 1.2;
      }
      p{
        font-weight: 500;
        margin-top: 1.5rem;
        font-size: 16px;
        line-height: 1.625;
        margin-bottom: 1.5rem;
        padding-left: 0px;
      }
    }
    .form-row{
      margin-top: 5rem;
      p{
        font-size: 20px;
        color: rgb(37, 37, 37);
        line-height: 1.8;
        text-align: left;
        font-weight: 300;
        margin-bottom: 5px;
      }
      span{
        font-size: 14px;
        color: #8c8c8c;
        line-height: 1.286;
        text-align: left;
        a{
          color: rgb(136, 197, 89);
        }
      }
      #xcube-filled-button{
        margin-top: 1.5rem;
      }
      #xCubeDatetimePicker {
        padding: 20px 20px 20px 0;
        label{
          background-color: #f3f3f3 !important;
        }
        .datePicker{
          .react-date-picker__wrapper{
            .react-date-picker__inputGroup__input{
              background-color: #f3f3f3 !important;
            }
          }
        }
        .timePicker{
          .react-time-picker__wrapper{
            .react-time-picker__inputGroup__input, .react-time-picker__inputGroup__leadingZero{
              background-color: #f3f3f3 !important;
            }
          }
        }
      }
      #select{
        select{
          background-color: #ebebeb !important;
          font-size: 16px;
        }
        button{
          color: #6b6b6b !important;
        }
        .form-group{
          background-color: #ebebeb !important;
          .form-label{
            background-color: #ebebeb !important;
            font-size: 12px;
          }
        }
        .xcube-input-label{
          background-color: #f3f3f3 !important;
        }
        .react-inputs-validation__select__options-container___d54qE{
          z-index: 999 !important;
        }
      }
      .nav-link{
        display: inline;
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .container-fluid{
      padding-left: 15px;
      padding-right: 15px;
    }
    .d-mob-none{
      display: none;
    }
    .w-50{
      width: 80% !important;
    }
  }

  @media screen and (min-width: 0px) {
    .container-fluid{
      .hero-text{
        h1{
          font-size: 14px;
        }
        p{
          font-size: 10px;
          margin-bottom: 6rem;
        }
      }
    }
  }
  @media screen and (min-width: 360px) {
    .container-fluid{
      .hero-text{
        h1{
          font-size: 28px;
        }
        p{
          font-size: 12px;
          margin-bottom: 5rem;
        }
      }
    }
  }
  @media screen and (min-width: 860px) {
    .container-fluid{
      .hero-text{
        h1{
          font-size: 32px;
        }
        p{
          font-size: 14px;
          margin-bottom: 3rem;
        }
      }
    }
  }
  @media screen and (min-width: 1000px) {
    .container-fluid{
      .hero-text{
        h1{
          font-size: 40px;
        }
        p{
          font-size: 16px;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
