#select{
  padding: 20px 20px 20px 0;
  button:focus {outline:0;}
  .react-inputs-validation__select__dropdown-name___3hghL{
    font-size: 1rem;
    font-weight: 300;
  }
  .react-inputs-validation__select__container___3jgUR{
    border-radius: 4px;
    //padding: 1.5% 2% 1% 2%;
  }
  .react-inputs-validation__container_identifier{
    padding-left: 10px;
  }
  button{
    padding: 0;
  }
  .xcube-input-label{
    transform: translate(10px, -50%);
    background-color: white;
    color: #8c8c8c;
    font-size: 12px;
    padding: 0 10px 0 10px;
    position: absolute;
    z-index: 1;
  }
  select{
    border-radius: 4px !important;
    font-size: 1rem;
    line-height: 2;
    color: #747d85 !important;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(./assets/arrow-down.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-position-x: 99%;
    border: 1px solid #ced4db !important;
  }
  .form-label{
    font-size: 12px;
    color: #8c8c8c;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    line-height: 2;
    position: absolute;
    //background-color: #252525;
    padding: 0 10px 0 10px;
    transform: translate(10%, -50%);
  }
  .form-group{
    //background-color: #252525 !important;
    margin: 0;
  }

   .react-inputs-validation__container_identifier {
     color: #2c2c2c;
   }

  .react-inputs-validation__select__container___3jgUR.react-inputs-validation__error___2aXSp{
    border-color: #f78b60;
  }
  .react-inputs-validation__msg___pxv8o.react-inputs-validation__error___2aXSp{
    color: #f78b60;
  }
  .react-inputs-validation__select__hover-active___2z1MQ{
    background: #b9c54d;
    color: white;
  }

  @media (max-width: 320px) {

    .react-inputs-validation__select__dropdown___11yDr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px;
    }

    .react-inputs-validation__select__options-container___d54qE {
      width: 130px;
    }

    .react-inputs-validation__select__options-container___d54qE div:first-of-type {
      font-size: 12px !important;
    }
  }

  @media (min-width: 321px) and (max-width: 767px) {


    .react-inputs-validation__select__options-container___d54qE div:first-of-type {
      font-size: 12px !important;
    }

    .react-inputs-validation__select__dropdown___11yDr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px;
  }

  }


}
