#dashboard-navbar{
  *:focus {
    outline: none;
}


  .navbar{
    background-image: linear-gradient(0deg, rgba(18,18,18,0) 0%, rgba(18,18,18,0.6) 100%);
    .navbar-brand{
      img{
        width: 80%;
      }
    }

    .navbar-toggler-icon {
      
    
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      
      
    } 
    
    .nav-link{
      font-size: 16px;
      color: #f5f5f5;
      font-weight: 500;
      text-align: right;
      line-height: 3;
    }
    .dropdown-toggle{
      background: none;
      border: none;
    }
    .dropdown-toggle:focus{
      outline: none;
      box-shadow: none;
    }

   
    .dropdown-toggle::after{
      display: inline-block;
    }
    
    .dropdown:focus{
      outline: none;
    }
    .avatar-link{
      color: white;
      line-height: 3.2;
      display: inline-block;
      
      span{
        transform: translate(-170%, 36%);
        display: inline-table;
        font-size: 12px;
        color: #dddddd;
      }
    }
  }
}

@media (max-width:767px) {

  .dropdown {
    width: 270px;
    margin: 0 auto;
  }


  .dropdown-toggle {
    padding-left: 10px;
    padding-right: 10px;
    width: 200px;
    margin: 5px auto;
  }
}