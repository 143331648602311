#xcube-filled-button{
  .negative{
    font-size: 15px;
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    background-image: linear-gradient(45deg, #cb3333 0%, #c57f34 100%);
    border: none;
    padding: 5px 20px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .white{
    font-size: 15px;
    color: #8ec862;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    background-color: #f4f4f4;
    border: 1px solid #8ec862;
    padding: 5px 20px;
  }
  button:hover{
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
    text-decoration: none;
    -webkit-filter: invert(10%);
    filter: invert(10%) contrast(2)
  }
  button:disabled{
    background-image: none !important;
    background-color: #e6e6e6;
    opacity: 9;
  }
  .normal{
    color: #ffffff;
    font-size: 15px;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    background-image: linear-gradient(45deg, #57ad63 0%, #cbc046 100%);
    border: none;
    padding: 5px 20px;
  }
}
