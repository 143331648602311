#integrationDialog{
  font-family: "Raleway", sans-serif;
  width: 70%;
  max-width: 70%;
  .modal-body{
    background-color: #f5f5f5;
    padding: 30px 50px;
    .card{
      cursor: pointer;
    }
  }
  .modal-content{
    height: fit-content;
  }
  .modal-header{
    span{
      font-weight: 300;
      font-size: 20px;
      color: #252525;
      font-family: "Raleway", sans-serif;
    }
    small{
      color: #aaaaaa;
      font-size: 14px;
      line-height: 1.2;
    }
    button{
      span{
        color: #99cc66 !important;
        font-family: auto;
        font-weight: 500;
        font-size: 25px;
      }
    }
  }
  .modal-footer{
    div{
      margin-left: auto;
      margin-right: auto;
      hr{
        border: 0.5px dashed #aaaaaa;
        position: absolute;
        width: 23%;
        transform: translate(10px, 15px);
        z-index: 1;
      }
      .step-btn{
        div{
          display: inline-block;
        }
        z-index: 2;
        margin-right: 30px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px dashed #aaaaaa;
        background-color: transparent;
        color: #aaaaaa;
        span{
          display: block;
          font-weight: bold;
          font-size: 25px;
          line-height: 0.5;
        }
      }
      .step-btn.active{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: none;
        background-color: #99cc66;
        color: white;
        span{
          display: block;
          font-weight: bold;
          font-size: 25px;
          line-height: 0.5;
        }
      }
    }

  }

}
