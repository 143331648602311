

.visitors_per_country {
  color: #252525;
  font-size: 16px;

  h5 {
    font-weight: 600;
  }

  .visitors {
    h5 {
      color: #8c8c8c;

    }


  }


}


