#get-on-top{
  h3{
    font-size: 35px;
    color: rgb(97, 97, 97);
    text-transform: uppercase;
    line-height: 1.2;
    text-align: right;
    font-weight: 800;
  }
  p{
    font-size: 16px;
    color: rgb(97, 97, 97);
    font-weight: bold;
    line-height: 1.625;
    text-align: right;
    margin-top: 2rem;
  }
  a{
    float: right;
    color: #7c7c7c;
    font-size: 15px;
  }
}