#paymentPopup{
  font-family: "Raleway", sans-serif;
  .modal-content{
    height: fit-content;
    .modal-body{
      padding: 2rem;
      background-color: #f5f5f5 !important;
      .card{
        cursor: pointer;
        padding: 20px;
        text-align: center;
        border: 1px dashed #bfbfbe;
        .card-title{
          color: #616161;
          font-size: 16px;
          margin-top: 10px;
        }
        .card-text{
          color: #aaaaaa;
          font-size: 13px;
          font-weight: lighter;
        }
      }
      .stripe-card-element{
        padding-top: 12px;
        font-weight: 300;
      }
    }
    .modal-title{
      text-align: center;
      h5{
        color: #616161;
        font-size: 18px;
        font-weight: lighter;
      }
      p{
        color: #aaaaaa;
        font-size: 13px;
        font-weight: lighter;
      }
    }
    .modal-header{
      border: none;
      span{
        font-weight: 300;
        font-size: 20px;
        color: #252525;
        font-family: "Raleway", sans-serif;
      }
      button{
        span{
          color: #99cc66 !important;
          font-family: auto;
          font-weight: 500;
          font-size: 25px;
        }
      }
    }
    .modal-footer{
      background-color: #f5f5f5 !important;
    }
    a{
      text-decoration:none;
    }
  }

}
