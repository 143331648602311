#dashboardAccountPlanBilling{
  background-color: white;
  .section-head {
    h1 {
      font-size: 30px;
      color: #252525;
      font-weight: 300;
    }
    h2 {
      font-size: 20px;
      line-height: 18px;
      color: #616161;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #aaaaaa;
      font-weight: 500;
    }
    span {
      color: #616161;
      font-size: 18px;
      font-weight: 500;
      line-height: 2;
    }
    a {
      color: #88c559;
    }
  }
  .main-card{
    border: 1px dashed #bfbfbe;
    .card{
      border: none;
      background: #f8f8f8;
    }
  }
  .card{
    .second-body{
      .card-text{
        color: #8c8c8c;
        font-size: 14px;
        margin: 0;
        font-weight: 500;
        small{
          color: #cacaca !important;
          cursor: pointer;
        }
      }
    }
  }
  hr{
    border-top: 1px dashed #bfbfbe;
  }
  .table{
    th{
      font-size: 14px;
      color: #616161;
      font-weight: 700;
    }
    thead{
      tr{
        background-color: #ebebeb;
        th{
          border: none;
        }
      }
    }
    tbody{
      font-size: 18px;
      line-height: 17px;
      color: #616161;
      font-weight: 500;
      tr{
        border-bottom: 1px dashed #dddddd;
        td{
          .status{
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: inline-flex;
            margin-right: 10px;
          }
          .positive{
            background-color: #88c559;
          }
          .negative{
            background-color: #f78b60;
          }
          border-top: none;
          p{
            font-size: 14px;
            color: #aaaaaa;
            line-height: 2;
          }
          .options{
            display: none;
            margin-top: 15px;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 0;
            }
            a:last-child{
              color: #f78b60;
            }
          }
          .options2{
            margin-top: 5px;
            color: #aaaaaa;
            font-size: 12px;
            a{
              font-size: 14px;
              font-weight: 500;
              color: #88c559;
              margin: 10px 10px 0 5px;
            }
          }
        }
      }
      tr:hover{
        filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
        background-color: #f5f5f5;
        border: 1px solid #ffffff;
        .options, .options2{
          display: block;
          span{
            color: #f78b60;
          }
          img{
            margin-right: 5px;
          }
        }
      }
    }
  }
  .import-export{
    text-align: center;
    margin: 3rem 0 3rem 0;
    a{
      color: #88c559;
      font-size: 16px;
      cursor: pointer;
      margin: .5rem;
    }
    span{
      margin-right: .5rem;
      color: #88c559;
    }
  }
  .newPaymentModal{
    #xcube-filled-button{
      float: right;
    }
  }

  @media (max-width: 767px) {
    .tableLead {
      overflow-x: auto;
    }

    
  }

  @media (min-width: 768px) {
    .expInvoices {
      display: inline-block
    }
    #xcube-filled-button {
      .normal {
        font-size: 14px;
      }
    }
  }
}