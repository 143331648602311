#xcube-textarea{
  padding: 20px 20px 20px 0;
  font-family: "Raleway", sans-serif;
  .xcube-textarea{
    background-color: #f5f5f5;
    color: #252525;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    border-radius: 4px;
    overflow: hidden;
    resize: none;
  }
  .xcube-textarea-default{
    border: 1px solid #495057;
  }
  .xcube-textarea-label {
    z-index: 1;
    color: #8c8c8c;
    position: absolute;
    background-color: #f5f5f5;
    padding: 0 10px;
    font-size: 12px;
    transform: translate(10%, -50%);
  }
  .xcube-textarea-typing{
    border: 1px solid #35604d;
  }
  .typing:focus {
    margin:0 auto;
    position:relative;
    filter: drop-shadow(0px 4px 8px rgba(0,0,0,0.15));
    background-color: #f5f5f5;
    border-color: lightgrey;
    box-shadow: none;
  }
  .background-image{
    background-image: none;
    padding: 1px;
    border-radius: 6px;
  }
  //.background-image:focus-within{
  //  background-image: linear-gradient(45deg, #57ad63 0%, #cbc046 100%);
  //  padding: 1px;
  //  .xcube-input-label{
  //    color: #57ad63;
  //  }
  //}
  .react-inputs-validation__textarea__wrapper___35GhF:focus-within{
    border-radius: 6px;
    background-image: linear-gradient(45deg, #57ad63 0%, #cbc046 100%);
      padding: 1px;
      .xcube-input-label{
        color: #57ad63;
      }
  }
  .react-inputs-validation__textarea__wrapper___35GhF.react-inputs-validation__error___2aXSp:focus-within{
    background-image: unset;
  }
  .typing:before {
    position:absolute;
    bottom:0;
    width:100%;
    height:80%;
    content:'';
    background: linear-gradient(transparent 0%,#b9c54d 100%);
    transform:scale(1.2);
  }
  .react-inputs-validation__msg_identifier{
    color: #f78b60;
    font-size: 12px;
    padding: 2px;
    .xcube-input{
      border: none;
    }
  }
  .xcube-textarea-error.react-inputs-validation__error___2aXSp{
    border: 1px solid #f78b60;
    border-radius: 6px;
  }
  .xcube-textarea-error{
    .typing:before{
      display: none;
    }
  }
}
