#addCompanyModal{
  .modal-content{
    height: fit-content;
  }
  .modal-body{
    background-color: #f5f5f5;
    .xcube-input-label{
      background-color: #f5f5f5 !important;
    }
  }
  .modal-header{
    span{
      font-weight: 300;
      font-size: 20px;
      color: #252525;
      font-family: "Raleway", sans-serif;
    }
    button{
      span{
        color: #99cc66 !important;
        font-family: auto;
        font-weight: 500;
        font-size: 25px;
      }
    }
  }
}

