#dashboardConversions{
  height: 100%;
  padding-bottom: 1.5rem;
  
    // .card{
    //   height: 100%;
    //   border: 1px dashed #aaaaaa;
    //   .card-title{
    //     font-size: 28px;
    //     color: #252525;
    //     font-weight: 300;
    //   }
    //   .card-text{
    //     line-height: 1.3;
    //     strong{
    //       color: #88c559;
    //       font-weight: normal;
    //     }
    //     font-size: 22px;
    //     font-weight: 500;
    //     color: #8c8c8c;
    //     margin:0;
    //     .text-muted{
    //       font-size: 16px;
    //       color: #8c8c8c;
    //       font-weight: 500;
    //     }
    //   }
    //   .card-text-data{
    //     font-size: 56px;
    //     color: #616161;
    //     font-weight: 300;
    //     line-height: 0.75;
    //   }
    //   .card-img{
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     width: 100%;
    //     margin-top: -60%;
    //     margin-left: -40%;
    //   }
    // }
  
    .visitorImg{
      width: 100px;
      margin-top: 0px;
      margin-left: 15px;
    }
  
    .card{
      height: 100%;
      border: 1px dashed #aaaaaa;
      .card-title-visitors{
        font-size: 26px;
        color: #8c8c8c;
        font-weight: 300;
        line-height: 1;
      }
      .card-text{
        line-height: 1.3;
        .strongVisitors{
          color: #8c8c8c;
          font-weight: 400;
          font-size: 32px;
        }
        small {
            font-size: 18px;
        }
        font-size: 20px;
        font-weight: 200;
        color: #8c8c8c;
        margin:0;
        .text-muted{
          font-size: 16px;
          color: #8c8c8c;
          font-weight: 500;
        }
      }
      .card-text-data{
        font-size: 56px;
        color: #616161;
        font-weight: 300;
        line-height: 0.75;
      }
      .card-img{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        margin-top: -60%;
        margin-left: -40%;
      }
      .card-body-time {
        padding: 0px 0px 0px 1rem;
      }
      .card-body {
        #conv {
          color: #88c559;
          font-weight: normal;
          
        }
      }

      
    }

    @media (max-width: 320px) {
      .visitorImg {
        width: 80px;
        margin-top: 0px;
        margin-left: 18px;
      }

      .card {
        small {
          font-size: 17px !important;
        }
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      .visitorImg {
        width: 90px;
        margin-top: 0px;
        margin-left: 18px;
      }
      .card {
        .card-text {
          small {
            font-size: 16px
          }
        }
        
      }
    }

  }
  
